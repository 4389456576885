import React from 'react';

import Lk from './pages/Lk';
import Login from './pages/Login';
import DataManager from './DataManager';
import { useLocation } from 'react-router-dom';
import Registration from './pages/Registration';

function App() {
  const location = useLocation();
  const isRegistration = location.pathname.split('/')[1] === 'registration';
  const [auth, setAuth] = React.useState(DataManager.retrieveUser() !== null);

  return (
    <>
      <div
        className='App'
        style={{
          height: '100vh',
          overflowY: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {auth ? (
          <Lk />
        ) : isRegistration ? (
          <Registration />
        ) : (
          <Login onSuccess={() => setAuth(true)} />
        )}
      </div>
    </>
  );
}

export default App;