import style from './SshModal.module.scss';
import close from '../../assets/img/close.svg';
import dotsLeftUp from './assets/dots-left-up.svg';
import dotsRightDown from './assets/dots-right-down.svg';
import { useRef, useState } from 'react';
import ApiManager from '../../ApiManager';

const SshModal = ({ SSHModal, setSSHModal }) => {
  const sshKeyRef = useRef();
  const sshNameRef = useRef();
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isDefault, setIsDefault] = useState(false);

  const saveSSHKey = async (e) => {
    e.preventDefault();

    try {
      const response = await ApiManager.saveSSH({
        id: SSHModal.id,
        ssh: sshKeyRef.current.value,
        sshName: sshNameRef.current.value,
        isDefault: isDefault
      });

      if (!response.success) {
        setError(await response.message);
      } else {
        setSuccessMessage('SSH ключ успешно установлен');
      }
    } catch (error) {
        setError('SSH ключ не установлен, произошла ошибка при выполнении запроса');
      console.log(error);
    }
  };

    const handleChange = (event) => {
        setIsDefault(event.target.checked);
  };

  return (
    <div className={style.modal}>
      <div
        onClick={() =>
          setSSHModal((prevState) => ({
            isOpen: false,
            id: 0,
          }))
        }
        className={style.modal__bg}
      ></div>
      <div className={style.modalContent}>
        <div className={style.modalContent__bg}>
          <div className={style.modalContent__bgElem}>
            <img src={dotsLeftUp} alt='' />
          </div>
          <div className={style.modalContent__bgElem}>
            <img src={dotsRightDown} alt='' />
          </div>
        </div>

        <div
          onClick={() =>
            setSSHModal((prevState) => ({
              isOpen: false,
              id: 0,
            }))
          }
          className={style.modalContent__close}
        >
          <img src={close} alt='closeBtn' />
        </div>
        <div className={style.modalBody__title}>Установка SSH-ключа
          (<a href="/ssh_installation_instructions.pdf" target="_blank">Инструкция</a>)
        </div>
        <div className={style.modalBody}>
          <form onSubmit={saveSSHKey} className={style.modalBody__col}>
            <label className={style.modalBody__field}>
              <span>Ключ</span>
              <textarea ref={sshKeyRef} name='ssh-key-field'></textarea>
            </label>
            <label className={style.modalBody__field}>
              <span>Имя</span>
              <input ref={sshNameRef} name='ssh-name-field' />
            </label>
            <label className={style.modalBody__check}>
              <input type='checkbox' checked={isDefault} onChange={handleChange}/>
              <span className={style.fake}></span>
              <span className={style.text}>
                Автоматически добавлять на новые сервера
              </span>
            </label>
            <button type='submit' className={style.modalBody__btn}>
              Установить
            </button>
            {error.length > 0 && (
              <div className={style.modalBody__err}>{error}</div>
            )}
            {successMessage.length > 0 && (
              <div className={style.modalBody__success}>{successMessage}</div>
            )}
          </form>

        </div>
      </div>
    </div>
  );
};

export default SshModal;
