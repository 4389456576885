// Процессор
export const marksProcessor = [
  {
    value: 1,
    label: '1',
  },
  //{
  //  value: 2,
  //  label: '2',
  //},
  {
    value: 8,
    label: '8',
  },
];

// Память
export const marksMemory = [
  {
    value: 1,
    label: '1',
  },
  //{
  //  value: 8,
  //  label: '8',
  //},
  {
    value: 32,
    label: '32',
  },
];

// Хранилище
export const marksDisk = [
  {
    value: 1,
    label: '1',
  },
  //{
  //  value: 5,
  //  label: '5',
  //},
  //{
  //  value: 15,
  //  label: '15',
  //},
  //{
  //  value: 40,
  //  label: '40',
  //},
  {
    value: 120,
    label: '120',
  },
];

// Дни
export const marksDay = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 30,
    label: '30',
  },
];

export const marksDayWindows = [
  {
    value: 7,
    label: '7',
  },
  {
    value: 30,
    label: '30',
  },
];

// Количество
export const marksCount = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 4,
    label: '4',
  }
];

export const tarifficationPrice = [450, 900, 1800];