import React, { useCallback } from 'react';
import useConfigurationStore from '../../../stores/configuration';
import SelectCountry from './SelectCountry';
import styles from '../Config.module.scss';
import ApiManager from '../../../ApiManager';

export const TotalCard = ({ onSubmit }) => {
  const processorCount = useConfigurationStore((state) => state.processorCount);
  const memoryCount = useConfigurationStore((state) => state.memoryCount);
  const diskCount = useConfigurationStore((state) => state.diskCount);
  const dayCount = useConfigurationStore((state) => state.dayCount);
  const stopDrag = useConfigurationStore((state) => state.stopDrag);
  const setStopDrag = useConfigurationStore((state) => state.setStopDrag);

  const [finalPrice, setFinalPrice] = React.useState(0);

  const calculate = useCallback(async () => {
    const response = await ApiManager.calculatePrice({
        cpu: processorCount,
        ram: memoryCount,
        disk: diskCount,
        days: dayCount,
        count: 1,
    });

    if (response.success) {
        setFinalPrice(response.message);
    }
  }, [dayCount, diskCount, memoryCount, processorCount]);

  React.useEffect(() => {
    if (stopDrag) {
      calculate();
      setStopDrag(false);
    }

    if (finalPrice === 0) {
      setTimeout(() => { calculate() }, 100);
    }
  }, [stopDrag, finalPrice, calculate, setStopDrag]);

  return (
    <div className={styles.right}>
      <h3 className={styles.right__title}>Итого:</h3>
      <div className={styles.right__item}>
        <span className={styles.right__itemLabel}>Процессор</span>
        <span className={styles.right__itemValue}>{processorCount} Core</span>
      </div>
      <div className={styles.right__item}>
        <span className={styles.right__itemLabel}>Память</span>
        <span className={styles.right__itemValue}>{memoryCount} ГБ/RAM</span>
      </div>
      <div className={styles.right__item}>
        <span className={styles.right__itemLabel}>Хранилище</span>
        <span className={styles.right__itemValue}>{diskCount} ГБNVMe</span>
      </div>
      <div className={styles.right__item}>
        <span className={styles.right__itemLabel}>Дней</span>
        <span className={styles.right__itemValue}>{dayCount} Дней</span>
      </div>
      <div
        className={`${styles.right__item} ${styles.right__item__noBorder} ${styles.right__item__select_country}`}
      >
        <span className={styles.right__itemLabel}>Выбери страну</span>
        <SelectCountry />
      </div>

      <div className={styles.right__bottom}>
        <span className={styles.right__bottomTitle}>
          К оплате:
          <input
            title='Итоговая сумма'
            type='text'
            name="resultPrice"
            className={styles.finalValue}
            value={finalPrice}
            readOnly
          />
        </span>
        <button className={styles.right__btn} onClick={onSubmit}>
          купить
        </button>
      </div>
    </div>
  );
};