import React from 'react';
import ApiManager from '../ApiManager';
import style from './Server.module.scss';

import useConfigurationStore from '../stores/configuration';
import { ClickAwayListener } from '@mui/material';

const handleError = (response) => {
  if (!response.success) {
    return response.message;
  }
  return null;
};

const Server = ({
  data,
  index,
  setContModal,
  setContLinuxModal,
  setAllChecked,
  checkVps,
  setCheckVps,
  setSSHModal,
  toggleAutoRenewModal,
  updateUserInfo,
  updatedExpirationIds,
  updatedStatusIds,
  updatedPortIds,
  updatedCountryIds,
}) => {
  const updateVps = useConfigurationStore((state) => state.getAllVps);
  const setErrorText = useConfigurationStore((state) => state.setErrorText);

  const nameInputRef = React.useRef(null);
  const nameColRef = React.useRef(null);
  const [openAccord, setOpenAccord] = React.useState(false);
  const [changePassword, setChangePassword] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [repPassword, setRepPassword] = React.useState('');
  const [realPass, setRealPass] = React.useState(data.config.password);
  const [isEdit, setIsEdit] = React.useState(false);
  const [serverNameValue, setServerNameValue] = React.useState(null);
  const [price, setPrice] = React.useState(data.config.price7Day);
  const [expirationDate, setExpirationDate] = React.useState(data.expirationDate);
  const [vdsStatus, setVdsStatus] = React.useState(data.status);
  const [vdsPort, setVdsPort] = React.useState(data.port);
  const [vdsCountry, setVdsCountry] = React.useState(data.config.country);
  const [start, setStart] = React.useState(data.status === 0 || data.status === 4);
  const [stop, setStop] = React.useState(data.status === 1 || data.status === 4);
  const [reboot, setReboot] = React.useState(data.status === 1);
  const [rebootAfterWait, setRebootAfterWait] = React.useState(data.status === 4);
  const [endDateTime, setEndDateTime] = React.useState("");
  const [timeouts, setTimeouts] = React.useState([]);

  const statusList = [
    'Активный',
    'Остановлен',
    'Создается',
    'Добавлен',
    'В ожидании',
    'Настраивается',
    'В ожидании',
    'В ожидании',
    'В ожидании',
  ];
  const serverId = data.id;

  const onClickOpen = () => {
    setOpenAccord(!openAccord);
  };

  const onChecking = () => {
    let cloneCheckVps = Array.from(checkVps);
    if (cloneCheckVps.includes(data.id)) {
      let needIndex = cloneCheckVps.indexOf(data.id);
      if (needIndex !== -1) {
        cloneCheckVps.splice(needIndex, 1);
      }
      setAllChecked(false);
    } else {
      cloneCheckVps.push(data.id);
    }
    setCheckVps(cloneCheckVps);
  };

  const setPass = async () => {
    const dataFetch = {
      id: serverId,
      password: newPassword,
      isSave: true,
    };
    if (newPassword !== repPassword) {
      alert('Пароли не совпадают');
    } else if (newPassword.length <= 4) {
      alert('Пароль слишком короткий');
    } else if (!newPassword || !repPassword) {
      alert('Заполните оба поля');
    }
    if (
      newPassword &&
      repPassword &&
      newPassword === repPassword &&
      newPassword.length > 4
    ) {
      let setPasswordRequest = await ApiManager.setPassword(dataFetch);
      if (setPasswordRequest.success) {
        alert('Пароль успешно изменен');
        setRealPass(newPassword);
      } else {
        alert('Ошибка сервера');
      }
      setChangePassword(false);
    }
  };

  const startVps = async () => {
    const dataFetch = {
      id: serverId,
    };
    const errorText = handleError(await ApiManager.startVps(dataFetch));
    if (errorText) {
        setErrorText(errorText);
    } else {
        disableButtons();
    }
  };

  const stopVps = async () => {
    const dataFetch = {
      id: serverId,
    };
    const errorText = handleError(await ApiManager.stopVps(dataFetch));
    if (errorText) {
        setErrorText(errorText);
    } else {
        disableButtons();
    }
  };
  
  const rebootVps = async () => {
    const dataFetch = {
      id: serverId,
    };
    const errorText = handleError(await ApiManager.rebootVps(dataFetch));
    if (errorText) {
      setErrorText(errorText);
    } else {
        disableButtons();
        if (vdsStatus === 4) {
            setRebootAfterWait(true);
        }
        else {
            setRebootAfterWait(false);
        }
    }
  };

  const disableButtons = () => {
    setStart(true);
    setStop(true);
    setReboot(true);
  };

  const accordionClass = () => {
    const checkBoxId = 'check-' + data.id;
    let thisCheckBox = document.getElementById(checkBoxId);

    if (!thisCheckBox) {
        return;
    }
    setTimeout(() => {
        thisCheckBox.checked = false;
    }, 100);
    if (checkVps.includes(data.id) && openAccord) {
      setTimeout(() => {
          thisCheckBox.checked = true;
      }, 100);
      return 'panelAccord opened selected';
    } else if (checkVps.includes(data.id)) {
      setTimeout(() => {
          thisCheckBox.checked = true;
      }, 100);
      return 'panelAccord selected';
    } else if (openAccord) {
      setTimeout(() => {
          thisCheckBox.checked = false;
      }, 100);
      return 'panelAccord opened';
    } else {
      setTimeout(() => {
          thisCheckBox.checked = false;
      }, 100);
      return 'panelAccord';
    }
  };

  const saveName = async () => {
    if (isEdit) {
      setIsEdit(false);
      setServerNameValue(nameInputRef.current.value);
      const response = await ApiManager.saveVPSName({
        vpsList: [
          {
            id: data.id,
            name: nameInputRef.current.value,
          },
        ],
      });
      if (response.success) {
        updateUserInfo();
      }
    }
  };

  const disableAutoRenew = async () => {
    if (data?.autoRenewal) {
      const response = await ApiManager.toggleAutoRenew({
        vpsList: [
          {
            id: data.id,
            autoRenewal: false,
            autoRenewalDays: 1,
          },
        ],
      });

      if (response?.success) {
        updateUserInfo(true);
      }
    }
  };

  React.useEffect(() => {
    if (data) {
        switch (data.id) {
            case updatedExpirationIds.id:
                setExpirationDate(updatedExpirationIds.expirationDate);
                break;
            case updatedStatusIds.id:
                setVdsStatus(updatedStatusIds.status);

                switch (updatedStatusIds.status) {
                    case 0:
                        setReboot(false);
                        setStart(true);
                        setStop(false);

                        //очищаем таймауты
                        timeouts.forEach((t) => {
                            clearTimeout(t);
                        });
                        setTimeouts([]);
                        break;
                    case 1:
                        setStop(true);
                        setStart(false);
                        setReboot(true);

                        //очищаем таймауты
                        timeouts.forEach((t) => {
                            clearTimeout(t);
                        });
                        setTimeouts([]);
                        break;
                    case 4:
                        setStart(true);
                        // **** 60 sec disable button reboot ****
                        let timer1 = setTimeout(() => setReboot(rebootAfterWait), 60000);

                        timeouts.forEach((t) => {
                            clearTimeout(t);
                        });

                        setTimeouts([timer1]);

                        if (rebootAfterWait) {
                            // **** 5 min disable button reboot ****
                            let timer2 = setTimeout(() => {
                                setReboot(false);
                                rebootAfterWait(vdsStatus !== 4);
                            }, 300000);

                            setTimeouts([...timeouts, timer2]);
                        }
                        break;
                    default:
                        break;
                }

                break;
            case updatedPortIds.id:
                setVdsPort(updatedPortIds.port);
                break;
            case updatedCountryIds.id:
                setVdsCountry(updatedCountryIds.country);
                break;
            default:
                break;
        }

        var strDt = parseInt((expirationDate - new Date().getTime() / 1000) / 60 / 60 / 24) + 'д ';
        strDt += parseInt((expirationDate - new Date().getTime() / 1000) / 60 / 60) % 24 + 'ч ';
        strDt += parseInt(((expirationDate - new Date().getTime() / 1000) / 60) % 60) + 'м';

        setEndDateTime(strDt);

        const intervalId = setInterval(() => {
            var strDt = parseInt((expirationDate - new Date().getTime() / 1000) / 60 / 60 / 24) + 'д ';
            strDt += parseInt((expirationDate - new Date().getTime() / 1000) / 60 / 60) % 24 + 'ч ';
            strDt += parseInt(((expirationDate - new Date().getTime() / 1000) / 60) % 60) + 'м';

            setEndDateTime(strDt);
        }, 1000 * 60); // in milliseconds

        return () => clearInterval(intervalId);
    }
  }, [updatedExpirationIds, updatedStatusIds, updatedCountryIds, updatedPortIds, data, expirationDate]);

  return (
    <div className='accordion-item'>
      <div id={'accordion-button-' + index} className={accordionClass()}>
        <div className='wrap_inCH clearfix'>
          <div className='divCH'>
            <label className='containerCH'>
              <input
                placeholder='Выбрать VDS'
                type='checkbox'
                className='selSer'
                name='selSer'
                id={'check-' + data.id}
              />
              <span className='checkmark' onClick={() => onChecking()}></span>
            </label>
          </div>
          <div className='divCHimg'>
            <img src={data.config.osImage} className={style.osImage} alt='' />
          </div>
          <ClickAwayListener onClickAway={saveName}>
            <div
              ref={nameColRef}
              className={`divCHName eq-server-name ${
                isEdit && 'eq-is-editing'
              }`}
            >
              {isEdit ? (
                <input
                  ref={nameInputRef}
                  disabled={!isEdit}
                  max={255}
                  type='text'
                  defaultValue={
                    serverNameValue ? serverNameValue : data.vpsName
                  }
                />
              ) : (
                <span className='eq-server-name-span'>
                  {serverNameValue ? serverNameValue : data.vpsName}
                </span>
              )}

              {isEdit ? (
                <button title='Сохранить' onClick={saveName}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M4.5 12.75l6 6 9-13.5'
                    />
                  </svg>
                </button>
              ) : (
                <button
                  title='Изменить'
                  onClick={() => {
                    setIsEdit(true);
                  }}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                    />
                  </svg>
                </button>
              )}
            </div>
          </ClickAwayListener>
          <div className='flagCh eq-flag'>
            {vdsCountry && vdsCountry !== "" ? (
              <img
                src={`img/${vdsCountry.toLowerCase()}.svg`}
                alt=''
              />
            ) : (
              <img src={`img/unknown.svg`} alt='' />
            )}
          </div>
          <div className='ipCh'>
            <span>{data.ipAddress + ':' + vdsPort}</span>
          </div>
          <div className='statusCh'>
            <span className='good'>{statusList[vdsStatus]}</span>
          </div>
          <div
            className={`renewalCh ${style.renewalCh} ${
              data?.autoRenewal && style.active
            } ${data?.autoRenewal && 'active'}`}
          >
            <span onClick={disableAutoRenew}>выкл</span>
            <span
              onClick={() => {
                !data?.autoRenewal && toggleAutoRenewModal(price, [serverId]);
              }}
            >
              вкл
            </span>
          </div>
          <div className='dateCh'>
            {/* <span>{timestampToDate(data.expirationDate)}</span>
            <br /> */}
            {endDateTime.includes("-") && <span style={{ color: "red" }}>{endDateTime}</span>}
            {!endDateTime.includes("-") && <span>{endDateTime}</span>}
          </div>

          <div className='btnRespam'>
            <div className='btnCh'>
              <button
                title='Продлить'
                className='goMore'
                onClick={() => {
                if (data.isWindows) {
                    setContModal(true);
                }
                else {
                    setContLinuxModal(true);
                }
                  localStorage.setItem('serverToContinue', data.id);
                }}
              >
                Продлить
              </button>
            </div>
            <button
              tabIndex="-1"
              className={!openAccord ? 'icon fopitem' : 'icon fopitem rotate'}
              onClick={() => onClickOpen()}
              aria-hidden
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='13'
                height='9'
                viewBox='0 0 13 9'
                fill='none'
              >
                <line
                  y1='-0.5'
                  x2='9.68102'
                  y2='-0.5'
                  transform='matrix(0.658505 0.752577 -0.452865 0.891579 0 1)'
                  stroke='white'
                />
                <line
                  y1='-0.5'
                  x2='9.68102'
                  y2='-0.5'
                  transform='matrix(-0.658505 0.752577 0.452865 0.891579 12.75 1)'
                  stroke='white'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          !openAccord ? 'accordion-content' : 'accordion-content opened'
        }
      >
        <div className='contCH clearfix'>
          <div className='upravl'>
            <div className='gopom'>
              <button
                title='Остановить VDS'
                className='stop'
                disabled={stop}
                style={{
                  opacity: stop ? '0.4' : '1',
                  cursor: stop ? 'default' : 'pointer',
                }}
                onClick={() => stopVps()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                >
                  <rect width='20' height='20.1724' rx='2' fill='#C71519' />
                  <rect
                    x='5'
                    y='5.04297'
                    width='10'
                    height='10.0862'
                    rx='1'
                    fill='white'
                  />
                </svg>
              </button>
              <button
                title='Запустить VDS'
                className='play'
                disabled={start}
                style={{
                  opacity: start ? '0.4' : '1',
                  cursor: start ? 'default' : 'pointer',
                }}
                onClick={() => startVps()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                >
                  <rect width='20' height='20.1724' rx='2' fill='#159064' />
                  <path
                    d='M15 10.0869L6.75 15.3278L6.75 4.84593L15 10.0869Z'
                    fill='white'
                  />
                </svg>
              </button>
              <button
                title='Перезапустить VDS'
                className='reload'
                disabled={reboot}
                style={{
                  opacity: reboot ? '0.4' : '1',
                  cursor: reboot ? 'default' : 'pointer',
                }}
                onClick={() => rebootVps()}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                >
                  <rect width='20' height='20.1724' rx='2' fill='#EBBF25' />
                  <path
                    d='M15 11.2781C15 9.83739 14.4732 8.45568 13.5355 7.43695C12.5979 6.41821 11.3261 5.84589 10 5.84589V4.03516L7.77778 6.44947L10 8.86378V7.05305C12.15 7.05305 13.8889 8.94225 13.8889 11.2781C13.8889 13.6139 12.15 15.5031 10 15.5031C9.28567 15.504 8.58496 15.2909 7.97483 14.8872C7.36471 14.4836 6.86879 13.9051 6.54155 13.2153C6.2143 12.5254 6.06841 11.7509 6.11988 10.9769C6.17136 10.2028 6.41822 9.45915 6.83333 8.82757L6.03333 7.97049C5.54961 8.65533 5.22308 9.45557 5.08081 10.3049C4.93853 11.1542 4.98461 12.0281 5.21523 12.8542C5.44584 13.6803 5.85434 14.4348 6.40689 15.0551C6.95943 15.6755 7.6401 16.1438 8.39248 16.4213C9.14487 16.6989 9.9473 16.7776 10.7333 16.651C11.5193 16.5244 12.2661 16.1961 12.912 15.6934C13.5579 15.1906 14.0843 14.5278 14.4474 13.76C14.8105 12.9921 14.9999 12.1413 15 11.2781Z'
                    fill='white'
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className='users_data'>
            <div className='line1'>
              <div className='info_log'>
                Логин: <span>{data.config.username}</span>
              </div>
            </div>
            <div className='line2'>
              <div className='info_log'>
                Пароль: <span>{realPass}</span>
              </div>
              <div className='change_btn'>
                <div className='wisible'>
                  <button
                    title='Изменить пароль'
                    className='change_pass'
                    onClick={() => setChangePassword(true)}
                    style={
                      changePassword
                        ? { display: 'none' }
                        : { display: 'inline-block' }
                    }
                    url-pass='ch1'
                  >
                    Изменить
                  </button>
                </div>
                <div
                  className='unwisible'
                  style={
                    changePassword ? { display: 'block' } : { display: 'none' }
                  }
                  url-pass-ch='ch1'
                >
                  <div className='peckir'>
                    <div className='form-group'>
                      <label className='sr-only' htmlFor={'password1' + index}>
                        Новый пароль
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id={'password1' + index}
                        value={newPassword}
                        onChange={(event) => {
                          setNewPassword(event.target.value);
                        }}
                      />
                    </div>
                    <div className='form-group pad'>
                      <label className='sr-only' htmlFor={'password2' + index}>
                        Повторите пароль
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id={'password2' + index}
                        value={repPassword}
                        onChange={(event) => {
                          setRepPassword(event.target.value);
                        }}
                      />
                    </div>
                    <div className='form-group2'>
                      <button title='Изменить' className='normal' onClick={() => setPass()}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <g clipPath='url(#clip0_67_9109)'>
                            <rect
                              y='0.259766'
                              width='20'
                              height='20'
                              rx='2'
                              fill='#159064'
                            />
                            <path
                              d='M16.1082 3.48319C15.7991 3.28296 15.4232 3.21356 15.063 3.29022C14.7028 3.36689 14.3878 3.58335 14.1871 3.89208L8.13927 13.2137L5.34538 10.6304C5.212 10.5037 5.05477 10.4048 4.88284 10.3394C4.7109 10.2739 4.52768 10.2433 4.34381 10.2493C4.15994 10.2553 3.9791 10.2977 3.81177 10.3742C3.64444 10.4506 3.49396 10.5595 3.36907 10.6946C3.24419 10.8297 3.14738 10.9882 3.08428 11.161C3.02117 11.3338 2.99302 11.5174 3.00146 11.7012C3.00991 11.885 3.05477 12.0652 3.13346 12.2315C3.21215 12.3978 3.32308 12.5468 3.45983 12.6699L7.46483 16.3732C7.7315 16.6204 8.07038 16.7426 8.40705 16.7426C8.78094 16.7426 9.25927 16.586 9.57316 16.1099C9.75761 15.8287 16.5176 5.4043 16.5176 5.4043C16.6169 5.25128 16.685 5.0802 16.7182 4.90084C16.7513 4.72147 16.7488 4.53734 16.7108 4.35895C16.6728 4.18055 16.6 4.01139 16.4966 3.86113C16.3932 3.71087 16.2612 3.58245 16.1082 3.48319Z'
                              fill='white'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_67_9109'>
                              <rect
                                width='20'
                                height='20'
                                fill='white'
                                transform='translate(0 0.259766)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>
                    </div>
                    <div className='form-group2'>
                      <button
                        title='Закрыть'
                        className='normal closePass'
                        onClick={() => {
                          setChangePassword(false);
                        }}
                        url-pass-close='ch1'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='21'
                          viewBox='0 0 20 21'
                          fill='none'
                        >
                          <rect
                            y='0.259766'
                            width='20'
                            height='20'
                            rx='2'
                            fill='#C71519'
                          />
                          <path
                            d='M5 5.25977L15 15.2598M15 5.25977L5 15.2598L15 5.25977Z'
                            stroke='white'
                            strokeWidth='1.875'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!data.isWindows && (
              <div className='line2'>
                <div className='info_log'>
                  SSH-ключ:
                  <span>{/* key-1233451 */}</span>
                </div>
                <div className='change_btn'>
                  <div className='wisible'>
                    <button
                      title='Изменить SSH-ключ'
                      className='change_pass'
                      onClick={() =>
                        setSSHModal((prevState) => ({
                          isOpen: true,
                          id: data.id,
                        }))
                      }
                      style={{ display: 'inline-block' }}
                      url-pass='ch1'
                    >
                      Изменить
                    </button>
                  </div>
                </div>
              </div>
            )}
            <div className='line4'>
              <div className='info_log'>
                Описание: <span>IP-адрес и порт копировать вместе</span>
              </div>
            </div>
          </div>
          <div className='harakter'>
            <div className='lips'>Характеристики сервера:</div>
            <div className='infoHK'>
              Процессор: <span>{data.config.cpu} CPUs</span>
            </div>
            <div className='infoHK'>
              Память: <span>{data.config.ram} GB</span>
            </div>
            <div className='infoHK'>
              Диск: <span>{data.config.disk} GB HDD+SSD</span>
            </div>
          </div>
          <div className='harakter2'>
            <div className='lips2'>Важно:</div>
            <div className='text'>
              В “ожидании” означает процесс создания сервера, занимающий до 15
              минут.
            </div>
            <div className='text'>
              На VPS, перенесенных с старой версии, пароли с помощью кнопки
              слева не меняются.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Server;
