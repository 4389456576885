import React from 'react';
import Modal from 'react-modal';
import ApiManager from '../../../ApiManager';
import NumericProperty from './NumericProperty';

const rootContainer = document.getElementById("root");
Modal.setAppElement(rootContainer);

const VpsTimeModal = (props) => {

  const [dayCount, setDayCount] = React.useState(0);
  const [hourCount, setHourCount] = React.useState(0);
  const [minCount, setMinCount] = React.useState(0);

  const modalStyle = {
    content: {
      width: '800px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
    },
  };

  const addSubscription = async () => {
    let data = {
      days: dayCount,
      hours: hourCount,
      minutes: minCount,
      count: 1,
      vpsIds: props.params.vds.map((u) => u.id),
    };
    await ApiManager.editVps(data);
    props.vpsUpdated();
    props.onRequestClose();
  };

  let msg = props.params.vds
    .map((v) => (v.ipAddress ?? '') + ':' + (v.port ?? ''))
        .join(', ');

  return (
    <Modal
      isOpen={props.params.visible}
      onRequestClose={() => props.onRequestClose()}
      style={modalStyle}
      setAppElement={rootContainer}
      contentLabel="Example Modal"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <span className="non-selectable" style={{ fontWeight: '600' }}>
          Добавить подписку
        </span>
        <span className="non-selectable">{msg}</span>
        <NumericProperty
          width="100px"
          min={0}
          max={30}
          step={1}
          name="Дней"
          state={[dayCount, setDayCount]}
        />
        <NumericProperty
          width="100px"
          min={0}
          max={23}
          step={1}
          name="Часов"
          state={[hourCount, setHourCount]}
        />
        <NumericProperty
          width="100px"
          min={0}
          max={59}
          step={1}
          name="Минут"
          state={[minCount, setMinCount]}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <button
            className="vps_button non-selectable"
            onClick={addSubscription}
          >
            Готово
          </button>
          <button
            className="vps_button2 non-selectable"
            onClick={() => props.onRequestClose()}
          >
            Отмена
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default VpsTimeModal;