import React from 'react';
import styles from './Login.module.scss';
import compose from '../../assets/img/compose.svg';
import ApiManager from '../../ApiManager';
import DataManager from '../../DataManager';
import { Link } from 'react-router-dom';

const Login = ({ onSuccess }) => {
  const [step, setStep] = React.useState(1);
  const LoginError = {
    WRONG_PASSWORD: 'wrong_password',
    NOT_FILLED: 'not_filled',
  };
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [saveData, setSaveData] = React.useState(true);
  const [code, setCode] = React.useState('');
  const [provider, setProvider] = React.useState('');
  const [error, setError] = React.useState('');

  const lkAuthorize = async () => {
    if (username && password) {
      let user;
      if (step === 1) {
        user = await ApiManager.login({
          userName: username,
          password: password,
          hwid: 'string',
        });
      } else {
        user = await ApiManager.loginTwofa({
          user: { userName: username, password: password },
          provider,
          code,
          hwid: 'string',
        });
      }
      if (!user) {
        setError(LoginError.WRONG_PASSWORD);
        return;
      }
      if (step === 1 && user.providers) {
        setProvider(user.providers[0].value);
        setStep(2);
      } else {
        saveData
          ? DataManager.saveUserInLocalStorage(user)
          : DataManager.saveUserInSessionStorage(user);
        onSuccess();
      }
    } else {
      setError(LoginError.NOT_FILLED);
    }
  };

  React.useEffect(() => {
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter" || event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("login").click();
        }
    };

    document.addEventListener("keydown", listener);
    return () => {
        document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <div className={styles.login}>
      <div className={styles.login__row}>
        <div className={styles.login__rowLeft}>
          <div className={styles.login__left}>
            <h3 className={styles.login__title}>Добро пожаловать!</h3>
            <img src={compose} alt='' className={styles.login__pic} />
          </div>
        </div>
        <div className={styles.login__rowRight}>
          <div className={styles.loginForm}>
            <h3 className={styles.loginForm__title}>Вход</h3>
            {step === 1 ? (
              <>
                <div className={styles.loginForm__group}>
                  <input
                    type='text'
                    className={styles.loginForm__control}
                    placeholder='Логин'
                    value={username}
                    onChange={(e) => setUsername(e.target.value.trim())}
                    id='username'
                  />
                  <label className={styles.loginForm__label} htmlFor='username'>
                    Имя пользователя
                  </label>
                </div>
                <div className={styles.loginForm__group}>
                  <input
                    type='password'
                    className={styles.loginForm__control}
                    placeholder='Пароль'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id='password'
                  />
                  <label className={styles.loginForm__label} htmlFor='password'>
                    Пароль
                  </label>
                </div>
                <span
                  style={
                    error == LoginError.NOT_FILLED
                      ? {
                          color: 'red',
                          marginTop: -15,
                          marginBottom: 15,
                          display: 'block',
                        }
                      : { display: 'none' }
                  }
                >
                  Незаполненное поле
                </span>
                <span
                  style={
                    error == LoginError.WRONG_PASSWORD
                      ? {
                          color: 'red',
                          marginTop: -15,
                          marginBottom: 15,
                          display: 'block',
                        }
                      : { display: 'none' }
                  }
                >
                  Логин или пароль введены неверно
                </span>
                <div className={styles.loginForm__group}>
                  <div className={styles.loginForm__checkbox}>
                    <input
                      type='checkbox'
                      id='save'
                      checked={saveData ? 'checked' : ''}
                      onChange={() => setSaveData(!saveData)}
                    />
                    <label htmlFor='save'>Запомнить</label>
                  </div>
                </div>
                <div className={styles.loginForm__submit}>
                  <button onClick={() => lkAuthorize()} id="login">продолжить</button>
                </div>
                <div className={styles.loginForm__registration}>
                  Если у вас еще нет аккаунта, то вы можете
                  <Link to={'registration'}>зарегистрироваться</Link>
                </div>
              </>
            ) : (
              <>
                <p>
                  Код аутентификации указан в приложении Google Authenticator
                  (IOS - Android) или Authy (IOS - Android).
                </p>
                <div className={styles.loginForm__group}>
                  <input
                    type='password'
                    className={styles.loginForm__control}
                    placeholder='Пароль'
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    id='password'
                  />
                  <label className={styles.loginForm__label} htmlFor='password'>
                    Код аутентификации
                  </label>
                </div>
                <span
                  style={
                    error == LoginError.WRONG_PASSWORD
                      ? {
                          color: 'red',
                          marginTop: -15,
                          marginBottom: 15,
                          display: 'block',
                        }
                      : { display: 'none' }
                  }
                >
                  Неправильный код
                </span>
                <div className={styles.loginForm__submit}>
                  <button onClick={() => lkAuthorize()}>продолжить</button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
