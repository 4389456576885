import { Box, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Checkbox } from '@mui/material';

const FiltersStatistic = (props) => {
    const {
        nodesNamesList,
        checked,
        setChecked,
        nodesNames,
        setNodesNames
    } = props;

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        var currentlyName = nodesNamesList[value];
        const currentIndexName = nodesNames.indexOf(currentlyName);

        const newNames = [...nodesNames];

        if (currentIndexName === -1) {
            newNames.push(nodesNamesList[value]);
        } else {
            newNames.splice(currentIndexName, 1);
        }

        setChecked(newChecked);
        setNodesNames(newNames);
    };

    return (
        <Box>
            <List sx={{ width: '100%', minWidth: 180, bgcolor: 'background.paper' }}>
              {nodesNamesList.map((value, index) => {
                const labelId = `checkbox-list-label-${value}`;

                return (
                  <ListItem
                    key={index}
                    disablePadding
                  > 
                    <ListItemButton role={undefined} onClick={handleToggle(index)} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={checked.indexOf(index) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={`${value}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
        </Box>
    );
}

export default FiltersStatistic