import create from 'zustand';
import ApiManager from '../ApiManager';

export const useConfigurationStore = create((set) => ({
  processorCount: 2,
  memoryCount: 8,
  diskCount: 40,
  dayCount: 7,
  country: {
    name: 'All',
    src: null,
  },
  allVps: [],
  errorText: '',
  os: '',
  stopDrag: true,

  setProcessorCount: (value) => set(() => ({ processorCount: value })),
  setMemoryCount: (value) => set(() => ({ memoryCount: value })),
  setDiskCount: (value) => set(() => ({ diskCount: value })),
  setDayCount: (value) => set(() => ({ dayCount: value })),
  setCountry: (value) => set(() => ({ country: value })),
  setErrorText: (value) => set(() => ({ errorText: value })),
  setOs: (os) => set(() => ({ os })),
  setStopDrag: (stopDrag) => set(() => ({ stopDrag })),

  getAllVps: async () => {
    set({ allVps: await ApiManager.allVps() });
  },
}));

export default useConfigurationStore;
