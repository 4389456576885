import React from 'react';
import Modal from 'react-modal';
import NumericProperty from '../Vps/NumericProperty';
import ApiManager from '../../../ApiManager';
import styles from './UserModal.module.scss';

const rootContainer = document.getElementById("root");
Modal.setAppElement(rootContainer);

const UserModal = (props) => {
    const [userData, setUserData] = React.useState({ login: "", password: "", promocode: "", email: "", accountBalance: 0 });
    const [refPercent, setRefPercent] = React.useState(0);
    const [myRefPercent, setMyRefPercent] = React.useState(0);

    const createUser = async () => {
        if (userData.login === "" || userData.password === ""){
            props.onRequestClose()
            return
        }
        let response = await ApiManager.createUser({
            userName: userData.login,
            password: userData.password,
            myReferalCode: userData.promocode,
            email: userData.email,
            referalPercent: refPercent,
            myReferalPercent: myRefPercent,
            accountBalance: userData.accountBalance
        })
        if (response.success){
            props.userCreated()
            props.onRequestClose()
        }
    }

    const updateUser = async () => {
        let prevLogin = props.params.user.userName ?? ""
        let prevPassword = props.params.user.password ?? ""
        let prevPromo = props.params.user.myReferalCode ?? ""
        let prevRefPercent = props.params.user.referalPercent ?? 0
        let prevMyRefPercent = props.params.user.myReferalPercent ?? 0
        let prevMyBalance = props.params.user.accountBalance ?? 0
        let prevEmail = props.params.user.email ?? "";
        if (userData.login === prevLogin && userData.password === prevPassword && userData.promocode === prevPromo
        && refPercent === prevRefPercent && myRefPercent === prevMyRefPercent && userData.email === prevEmail && userData.accountBalance === prevMyBalance){
            props.onRequestClose()
            return
        }
        let response = await ApiManager.updateUser({
            id: props.params.user.id,
            userName: userData.login,
            password: userData.password,
            email: userData.email,
            myReferalCode: userData.promocode,
            referalPercent: refPercent,
            myReferalPercent: myRefPercent,
            accountBalance: userData.accountBalance
        })
        if (response.success){
            props.userCreated()
            props.onRequestClose()
        }
    }

    React.useEffect(() => {
        if (props.params.user != null) {
            setUserData({ login: props.params.user.userName ?? "", password: props.params.user.password ?? "", promocode: props.params.user.myReferalCode ?? "", email: props.params.user.email ?? "", accountBalance: props.params.user.accountBalance ?? 0 })
            setRefPercent(props.params.user.referalPercent ?? 0)
            setMyRefPercent(props.params.user.myReferalPercent ?? 0)
        }
        else{
            setUserData({ login: "", password: "", promocode: "", accountBalance: 0 })
            setRefPercent(0)
            setMyRefPercent(0)
        }
    }, [props]);

    const userModalStyle = {
        content: {
          width: '800px',
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          borderRadius: '10px',
          transform: 'translate(-50%, -50%)',
        },
    };  
    
    return <Modal
        isOpen={props.params.visible}
        onRequestClose={() => props.onRequestClose()}
        style={userModalStyle}
        setAppElement={rootContainer}
        contentLabel="User Modal"
    >
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <span className='non-selectable' style={{ fontWeight: '600' }}>{props.params.user == null ? "Новый пользователь" : "Редактировать пользователя"}</span>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '16px'}}>
                <input
                    name="login"
                    onChange={(e) => setUserData({ ...userData, login: e.target.value })}
                    value={userData.login}
                    type="text"
                    placeholder='Логин'
                    style={{ padding: '8px', border: '2px solid #2A73DE', borderRadius: '8px', width: '200px' }} />
                <input
                    name="password"
                    onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    value={userData.password}
                    type="password"
                    placeholder='Пароль'
                    style={{ padding: '8px', border: '2px solid #2A73DE', borderRadius: '8px', width: '200px' }} />
                <input
                    name="promocode"
                    onChange={(e) => setUserData({ ...userData, promocode: e.target.value })}
                    value={userData.promocode}
                    type="text"
                    placeholder='Промокод'
                    style={{ padding: '8px', border: '2px solid #2A73DE', borderRadius: '8px', width: '200px' }} />
            </div>
            <div className={styles.row}>
                <NumericProperty state={[refPercent, setRefPercent]} name="Выплата" step={5} min={0} max={50} width={'100px'} gap={"18px"} />
                <input
                    name="email"
                    autoComplete="on" 
                    onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    value={userData.email}
                    type="text"
                    placeholder='Email'
                    style={{ padding: '8px', border: '2px solid #2A73DE', borderRadius: '8px', width: '200px' }} />
            </div>
            <NumericProperty state={[myRefPercent, setMyRefPercent]} name="Скидка" step={5} min={0} max={50} width={'84px'} gap={'32px'} />
            <div className={styles.row}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "32px",
                    }}
                >
                    <span
                        className="non-selectable"
                        style={{
                            width: "84px",
                            userSelect: "none",
                            fontWeight: "600",
                            textAlign: "end"
                        }}
                    >
                        Баланс
                    </span>
                    <input
                        name="myBalance"
                        onChange={(e) => setUserData({ ...userData, accountBalance: e.target.value })}
                        value={userData.accountBalance}
                        type="text"
                        placeholder='Баланс'
                        style={{ padding: '8px', border: '2px solid #2A73DE', borderRadius: '8px', width: '240px' }} />
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center'}}>
            <button
            className='vps_button non-selectable'
            onClick={props.params.user == null ? createUser : updateUser}
          >
            Готово
          </button>
          <button
            className='vps_button2 non-selectable'
            onClick={() => props.onRequestClose()}
          >
            Отмена
          </button>
</div>
       </div>
    </Modal>
}

export default UserModal;