import React from "react";
import UpIcon from "mdi-react/PlayIcon";
import DownIcon from "mdi-react/PlayIcon";

const NumericProperty = (props) => {
    let min = props.min ?? 1;
    let max = props.max ?? 8;
    let step = props.step ?? 1;
    let unit = props.unit ?? "";
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: props.gap ?? "12px",
            }}
        >
            <span
                className="non-selectable"
                style={{
                    width: props.width ?? "240px",
                    userSelect: "none",
                    fontWeight: "600",
                    textAlign: "end"
                }}
            >
                {props.name}{unit ? " (" + unit + ")" : "" }
            </span>
            <div className="numeric-property">
                <input type="number" className="non-selectable" style={{ width: "190px" }} onChange={(e) => {
                    props.state[1](e.target.value);
                }} value={props.state[0]}>
                </input>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <UpIcon
                        size={20}
                        style={{
                            transform: "rotate(-90deg)",
                            marginTop: "0px",
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            props.state[1](
                                props.state[0] + step > max
                                    ? min
                                    : props.state[0] + step
                            )
                        }
                    />
                    <DownIcon
                        size={20}
                        style={{
                            transform: "rotate(90deg)",
                            marginTop: "-8px",
                            cursor: "pointer",
                        }}
                        onClick={() =>
                            props.state[1](
                                props.state[0] - step < min
                                    ? max
                                    : props.state[0] - step
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default NumericProperty;
