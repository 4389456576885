import React from 'react';
import styles from './Config.module.scss';
import { CustomSlider } from './components/CustomSlider';
import { TotalCard } from './components/TotalCard';

import {
    marksProcessor,
    marksMemory,
    marksDisk,
    marksDay,
    marksDayWindows,
    tarifficationPrice,
} from './constants';

import useConfigurationStore from '../../stores/configuration';

import tarifIcon from '../../assets/img/tarif-icon.svg';
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ApiManager from "../../ApiManager";

export const Configuration = (props) => {
    const [calculateInfo, setCalculateInfo] = React.useState(null);
    const [defCpu, setDefCpu] = React.useState(1);
    const [defRam, setDefRam] = React.useState(1);
    const [defDisk, setDefDisk] = React.useState(1);
    const [cpuMin, setCpuMin] = React.useState(null);
    const [cpuMax, setCpuMax] = React.useState(null);
    const [cpuStep, setCpuStep] = React.useState(null);
    const [ramMin, setRamMin] = React.useState(null);
    const [ramMax, setRamMax] = React.useState(null);
    const [ramStep, setRamStep] = React.useState(null);
    const [diskMin, setDiskMin] = React.useState(null);
    const [diskMax, setDiskMax] = React.useState(null);
    const [diskStep, setDiskStep] = React.useState(null);
    const [daysDef, setDaysDef] = React.useState(null);
    const [daysMin, setDaysMin] = React.useState(null);
    const [daysMax, setDaysMax] = React.useState(null);
    const [daysStep, setDaysStep] = React.useState(null);
    const [daysLabel, setDaysLabel] = React.useState("дней");
    const [activeTarif, setActiveTarif] = React.useState(0);
    const [osList, setOsList] = React.useState(null);
    const [isWindows, setIsWindows] = React.useState(true);

    const processorCount = useConfigurationStore((state) => state.processorCount);
    const setProcessorCount = useConfigurationStore(
        (state) => state.setProcessorCount
    );

    const memoryCount = useConfigurationStore((state) => state.memoryCount);
    const setMemoryCount = useConfigurationStore((state) => state.setMemoryCount);

    const diskCount = useConfigurationStore((state) => state.diskCount);
    const setDiskCount = useConfigurationStore((state) => state.setDiskCount);

    const dayCount = useConfigurationStore((state) => state.dayCount);
    const setDayCount = useConfigurationStore((state) => state.setDayCount);

    const setStopDrag = useConfigurationStore((state) => state.setStopDrag);

    function setConfig(cpu, ram, disk) {
        setDefCpu(cpu);
        setDefRam(ram);
        setDefDisk(disk);

        setProcessorCount(cpu);
        setMemoryCount(ram);
        setDiskCount(disk);
    };

    function UpdateConfig(calculatorInfo) {
        setCpuMin(calculatorInfo.cpu_min_count);
        setCpuMax(calculatorInfo.cpu_max_count);
        setCpuStep(calculatorInfo.cpu_step);

        setRamMin(calculatorInfo.ram_min_count);
        setRamMax(calculatorInfo.ram_max_count);
        setRamStep(calculatorInfo.ram_step);

        setDiskMin(calculatorInfo.disk_min_count);
        setDiskMax(calculatorInfo.disk_max_count);
        setDiskStep(calculatorInfo.disk_step);

        setDaysDef(calculatorInfo.days_def_count);
        setDaysMin(calculatorInfo.days_min_count);
        setDaysMax(calculatorInfo.days_max_count);
        setDaysStep(calculatorInfo.days_step);

        setConfig(calculatorInfo.cpu_def_windows_count.value, calculatorInfo.ram_def_windows_count.value, calculatorInfo.disk_def_windows_count.value);
    }

    const os = useConfigurationStore(state => state.os);
    const setOsInStore = useConfigurationStore(state => state.setOs);

    const handleChange = (event) => {
        setOsInStore(event.target.value);

        if (calculateInfo === null) {
            return;
        }

        var osType = osList.find(osInList => osInList.id === event.target.value).osType;
       
        switch (osType) {
            case "windows":
                setConfig(calculateInfo.cpu_def_windows_count.value, calculateInfo.ram_def_windows_count.value, calculateInfo.disk_def_windows_count.value);
                setDayCount(calculateInfo.days_def_count.value);
                setDaysLabel("дней");
                setIsWindows(true);
                break;
            case "ubuntu":
                setConfig(calculateInfo.cpu_def_ubuntu_count.value, calculateInfo.ram_def_ubuntu_count.value, calculateInfo.disk_def_ubuntu_count.value);
                setIsWindows(false);
                break;
            case "debian":
                setConfig(calculateInfo.cpu_def_debian_count.value, calculateInfo.ram_def_debian_count.value, calculateInfo.disk_def_debian_count.value);
                setIsWindows(false);
                break;
            case "centos":
                setConfig(calculateInfo.cpu_def_centos_count.value, calculateInfo.ram_def_centos_count.value, calculateInfo.disk_def_centos_count.value);
                setIsWindows(false);
                break;
            default:
                break;
        }
        setStopDrag(true);
    };

    const checkMinCpu = (newValue) => {
        if (newValue < defCpu) {
            setProcessorCount(defCpu);
        } else {
            setProcessorCount(newValue);
            if (newValue >= 6 && memoryCount < 10) {
                setMemoryCount(10);
            }
            if (newValue >= 8 && memoryCount < 16) {
                setMemoryCount(16);
            }
        }
    };
    const checkMinRam = (newValue) => {
        let minCpuToRam = !(processorCount >= 6 && newValue < 10) && !(processorCount >= 8 && newValue < 16);

        if (newValue < defRam && minCpuToRam) {
            setMemoryCount(defRam);
        } else if (minCpuToRam) {
            setMemoryCount(newValue);
        }
    };
    const checkMinDisk = (newValue) => {
        if (newValue < defDisk) {
            setDiskCount(defDisk);
        } else {
            setDiskCount(newValue);
        }
    };

    const setDaysCountAndLabel = async (value) => {
        setDayCount(value);
        if ([1, 21].includes(dayCount)) {
            setDaysLabel("день");
        } else if ([2, 3, 4, 22, 23, 24].includes(dayCount)) {
            setDaysLabel("дня");
        } else {
            setDaysLabel("дней")
        }
    }

    React.useEffect(() => {
        const onMount = async () => {
            try {
                const { message, success } = await ApiManager.getListTemplates();
                if (success) {
                    setOsList(message);
                    setOsInStore(message[0].id)
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        onMount().then();
    }, [setOsInStore, setOsList]);

    React.useMemo(() => {
        const onMount = async () => {
            try {
                const { message, success } = await ApiManager.getCalculatorInfo();
                if (success) {
                    var newArray = [];

                    Object.entries(message).map(item => {
                        var itemName = item[1].name;
                        var itemValue = item[1].value;
                        newArray[itemName] = { value: itemValue };
                    });

                    setCalculateInfo(newArray);
                    UpdateConfig(newArray);
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        onMount().then();
    }, []);

    return (
        <div className={' i_cont'}>
            <div className="head_i">
                <div className="left_potr">
                    <div className="img_wrap">
                        <img src="img/config.svg" alt="config.svg"/>
                    </div>
                    <div className="title">Конфигуратор</div>
                </div>
            </div>
            <div className={styles.config}>
                <div className={styles.left}>
                    <CustomSlider
                        title={'Процессор'}
                        def={1}
                        min={cpuMin && cpuMin.value}
                        max={cpuMax && cpuMax.value}
                        step={cpuStep && cpuStep.value}
                        marks={marksProcessor}
                        buttonTitle={'Core'}
                        count={processorCount}
                        setCount={checkMinCpu}
                        setStopDrag={setStopDrag}
                    />
                    <CustomSlider
                        title={'Память'}
                        def={1}
                        min={ramMin && ramMin.value}
                        max={ramMax && ramMax.value}
                        step={ramStep && ramStep.value}
                        marks={marksMemory}
                        buttonTitle={'ГБ/RAM'}
                        count={memoryCount}
                        setCount={checkMinRam}
                        setStopDrag={setStopDrag}
                    />
                    <CustomSlider
                        title={'Хранилище'}
                        def={1}
                        min={diskMin && diskMin.value}
                        max={diskMax && diskMax.value}
                        step={diskStep && diskStep.value}
                        marks={marksDisk}
                        buttonTitle={'ГБ'}
                        count={diskCount}
                        setCount={checkMinDisk}
                        setStopDrag={setStopDrag}
                    />
                    <CustomSlider
                        title={'Кол-во дней'}
                        def={daysDef && daysDef.value}
                        min={isWindows ? 7 : daysMin && daysMin.value}
                        max={daysMax && daysMax.value}
                        step={daysStep && daysStep.value}
                        marks={isWindows ? marksDayWindows : marksDay}
                        buttonTitle={daysLabel}
                        count={dayCount}
                        setCount={setDaysCountAndLabel}
                        setStopDrag={setStopDrag}
                    />

                    {
                        osList && (
                            <div className={styles.select_os_wrapper}>

                                <p className={styles.label}>Выбор ОС</p>

                                <FormControl sx={{minWidth: 190}}
                                             size="small"
                                             className={styles.select_os}
                                >
                                    <Select value={os}
                                            defaultValue=""
                                            name = "SelectOs"
                                            onChange={handleChange}
                                            sx={{
                                                marginTop: 0,
                                                "& .MuiSelect-select": {
                                                    display: "flex",
                                                    alignItems: "center",
                                                    marginTop: 0,
                                                    border: "1px solid #2A73DE",
                                                    borderRadius: "10px!important",
                                                    padding: "10px",
                                                    "& p": {
                                                        margin: 0,
                                                        fontWeight: 600,
                                                        fontSize: "18px",
                                                        lineHeight: "22px",
                                                        color: "#3D3D3D",
                                                    },
                                                    "& img": {
                                                        marginLeft: "18px",
                                                        width: "24px",
                                                        height: "24px",
                                                    },
                                                },
                                                "& fieldset": {
                                                    border: "none",
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    display: "none",
                                                }
                                            }}
                                    >
                                        {
                                            osList.map(({id, osName, osImage}) => (
                                                <MenuItem value={id}
                                                          key={id}
                                                          sx={{
                                                              paddingTop: "10px",
                                                              paddingBottom: "10px",
                                                              paddingLeft: "9px",
                                                              display: "flex",
                                                              alignItems: "center",
                                                              "& p": {
                                                                  fontFamily: 'Montserrat',
                                                                  fontStyle: "normal",
                                                                  fontWeight: 600,
                                                                  fontSize: "18px",
                                                                  lineHeight: "22px",
                                                                  marginTop: 0,
                                                                  marginBottom: 0,
                                                                  color: "#808080",
                                                              },
                                                              "& img": {
                                                                  marginLeft: "18px",
                                                                  width: "24px",
                                                                  height: "24px",

                                                              },
                                                          }}
                                                >
                                                    <p>{osName}</p>
                                                    <img src={osImage} alt=""/>
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        )
                    }
                </div>
                <TotalCard onSubmit={props.setPayModal}/>
            </div>
            <div className="head_i">
                <div className="left_potr">
                    <div className="img_wrap">
                        <img src="img/tarif.svg" alt=""/>
                    </div>
                    <div className="title">Готовый тариф</div>
                </div>
            </div>
            <div className={styles.tarif}>
                <div className={styles.tarif__left}>
                    <h3 className={styles.tarif__title}>Готовый тариф</h3>
                    <div className={styles.tarif__wrapper}>
                        <i className={styles.tarifIcon}>
                            <img src={tarifIcon} alt=""/>
                        </i>
                        <p className={styles.tarif__txt}>
                            Готовый тариф для вашего удобства. Также вы можете создать свой
                            при помощи конфигуратора под ваши задачи.
                        </p>
                    </div>
                    <div className={styles.tarif__wrapper}>
                        <i className={styles.tarifIcon}>
                            <img src={tarifIcon} alt=""/>
                        </i>
                        <p className={styles.tarif__txt}>
                            *Скорость порта подключения к сети интернет - 1000 Мбит/сек.
                        </p>
                    </div>
                </div>
                <div className={styles.tarif__right}>
                    <h3 className={styles.tarif__title}>Кол-во дней:</h3>
                    <div className={styles.tarifSelect}>
                        <button
                            className={
                                activeTarif === 0
                                    ? styles.tarifSelect__item +
                                    ' ' +
                                    styles.tarifSelect__itemActive
                                    : styles.tarifSelect__item
                            }
                            onClick={() => {
                                setActiveTarif(0);
                                props.setActiveTariff(7);
                            }}
                        >
                            7
                        </button>
                        <button
                            className={
                                activeTarif === 1
                                    ? styles.tarifSelect__item +
                                    ' ' +
                                    styles.tarifSelect__itemActive
                                    : styles.tarifSelect__item
                            }
                            onClick={() => {
                                setActiveTarif(1);
                                props.setActiveTariff(14);
                            }}
                        >
                            14
                        </button>
                        <button
                            className={
                                activeTarif === 2
                                    ? styles.tarifSelect__item +
                                    ' ' +
                                    styles.tarifSelect__itemActive
                                    : styles.tarifSelect__item
                            }
                            onClick={() => {
                                setActiveTarif(2);
                                props.setActiveTariff(30);
                            }}
                        >
                            30
                        </button>
                    </div>
                    <div className={styles.tarifInfo}>
                        <div className={styles.tarifInfo__item}>
                            <span className={styles.tarifInfo__label}>RAM</span>
                            <span className={styles.tarifInfo__value}>8 GB</span>
                        </div>
                        <div className={styles.tarifInfo__item}>
                            <span className={styles.tarifInfo__label}>CPU Cores</span>
                            <span className={styles.tarifInfo__value}>2</span>
                        </div>
                        <div className={styles.tarifInfo__item}>
                            <span className={styles.tarifInfo__label}>SSD Space</span>
                            <span className={styles.tarifInfo__value}>40 GB</span>
                        </div>
                        <div className={styles.tarifInfo__item}>
                            <span className={styles.tarifInfo__label}>Bandwidth</span>
                            <span className={styles.tarifInfo__value}>1 TB</span>
                        </div>
                    </div>
                    <div className={styles.tarifInfo__bottom}>
                        <p className={styles.tarifInfo__price}>
                            {tarifficationPrice[activeTarif]} Р
                        </p>
                        <button
                            className={styles.right__btn}
                            onClick={() => {
                                props.setPayNonCustomModal(true);
                            }}
                            disabled={!osList}
                        >
                            купить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
