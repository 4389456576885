import { useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Stack, Box, Grid, Paper } from '@mui/material';
import { Gauge } from '@mui/x-charts/Gauge';

const GaugeStatistic = (props) => {
    const {
        nodesList
    } = props;

    const [nowPercentCpu, setNowPercentCpu] = useState(0);
    const [nowPercentRam, setNowPercentRam] = useState(0);
    const [nowPercentDisk, setNowPercentDisk] = useState(0);

    const [cpuText, setCpuText] = useState("");
    const [ramText, setRamText] = useState("");
    const [diskText, setDiskText] = useState("");
    const [vpsCountText, setVpsCountText] = useState("");

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    useMemo(() => {
        let nowCpu = nodesList.reduce((n, { nowCpu }) => n + nowCpu, 0);
        let maxCpu = nodesList.reduce((n, { maxCpu }) => n + maxCpu, 0);

        let nowRam = nodesList.reduce((n, { nowMem }) => n + nowMem, 0);
        let maxRam = nodesList.reduce((n, { maxMem }) => n + maxMem, 0);

        let nowDisk = nodesList.reduce((n, { nowDisk }) => n + nowDisk, 0);
        let maxDisk = nodesList.reduce((n, { maxDisk }) => n + maxDisk, 0);

        let allVpsCount = nodesList.reduce((n, { vpsCount }) => n + vpsCount, 0);

        let cpuPercent = nowCpu / maxCpu * 100;
        let ramPercent = nowRam / maxRam * 100;
        let diskPercent = nowDisk / maxDisk * 100;

        setNowPercentCpu(Math.round(cpuPercent));
        setNowPercentRam(Math.round(ramPercent));
        setNowPercentDisk(Math.round(diskPercent));

        setCpuText(`${nowCpu} шт. / ${maxCpu} шт.`);
        setRamText(`${nowRam} Gb / ${maxRam} Gb`);
        setDiskText(`${nowDisk} Gb / ${maxDisk} Gb`);
        setVpsCountText(`${allVpsCount} шт.`);
    }, [nodesList]);

    return (
        <Box spacing={{ xs: 1, sm: 2, md: 2 }}>
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row' }} spacing={{ xs: 1, sm: 1, md: 3 }}>
                <Gauge width={300} height={120} value={nowPercentCpu ? nowPercentCpu : 0} label="Cpu" startAngle={-90} endAngle={90}
                    text={
                        ({ value, valueMax }) => `CPU: ${value} / ${valueMax} %`
                    } />
                <Gauge width={300} height={120} value={nowPercentRam ? nowPercentRam : 0} startAngle={-90} endAngle={90}
                    text={
                        ({ value, valueMax }) => `RAM: ${value} / ${valueMax} %`
                    } />
                <Gauge width={300} height={120} value={nowPercentDisk ? nowPercentDisk : 0} startAngle={-90} endAngle={90}
                    text={
                        ({ value, valueMax }) => `DISK: ${value} / ${valueMax} %`
                    } />
            </Stack>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} padding={2}>
                <Grid item xs={6}>
                    <Item>Кол-во цпу</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>{cpuText}</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>Объём озу</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>{ramText}</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>Рамзер диска</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>{diskText}</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>Кол-во вдс на сервер{nodesList.length === 1 ? 'е' : 'ах'}</Item>
                </Grid>
                <Grid item xs={6}>
                    <Item>{vpsCountText}</Item>
                </Grid>
                {nodesList.length === 1 && <Grid item xs={6}>
                    <Item>Страна</Item>
                </Grid>}
                {nodesList.length === 1 && <Grid item xs={6}>
                    <Item>{nodesList[0].country}</Item>
                </Grid>}
            </Grid>
        </Box>
    );
}

export default GaugeStatistic