import React from 'react';
import Modal from 'react-modal';
import styles from './../Admin.module.scss';
import ApiManager from "../../../ApiManager";
import VpsProperty from "./NumericProperty";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const rootContainer = document.getElementById("root");
Modal.setAppElement(rootContainer);

const VdsModal = (props) => {
    const vdsModalStyle = {
        content: {
            maxWidth: "800px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            borderRadius: "10px",
            transform: "translate(-50%, -50%)",
        },
    };

    const [processorCount, setProcessorCount] = React.useState(2);
    const [memoryCount, setMemoryCount] = React.useState(8);
    const [diskCount, setDiskCount] = React.useState(40);
    const [dayCount, setDayCount] = React.useState(1);
    const [vpsCount, setVpsCount] = React.useState(1);
    const [country, setCountry] = React.useState(null);
    // Список типов операционных систем
    const [os, setOsInStore] = React.useState(null);
    const [osList, setOsList] = React.useState(null);

    // Получение списка операционных систем
    React.useEffect(() => {
        const onMount = async () => {
            try {
                const { message, success } = await ApiManager.getListTemplates();
                if (success) {
                    setOsList(message);
                    setOsInStore(message[0].id)
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        onMount().then();
    }, [])

    const handleChange = (event) => {
        setOsInStore(event.target.value);
    };

    const addVps = async () => {
        let response = await ApiManager.addVps({
            ram: memoryCount,
            cpu: processorCount,
            disk: diskCount,
            days: dayCount,
            hours: 0,
            minutes: 0,
            count: vpsCount,
            country: country,
            templateId: os,
            usersIds: [props.user.id],
        });
        if (response.success) {
            props.vpsUpdated();
            props.onRequestClose();
        } else {
            alert(response.message);
        }
    };

    return props.user == null ? (
        <span />
    ) : (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={() => props.onRequestClose()}
            style={vdsModalStyle}
            setAppElement={rootContainer}
            contentLabel="Vds Modal"
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <span className="non-selectable">
                    Добавить VPS{" "}
                    <span style={{ fontWeight: "bold" }}>
                        {props.user.userName}
                    </span>
                </span>
                <VpsProperty
                    state={[processorCount, setProcessorCount]}
                    name="Процессор"
                />
                <VpsProperty
                    state={[memoryCount, setMemoryCount]}
                    name="Память"
                    unit="Гб"
                    step={1}
                    min={1}
                    max={32}
                />
                <VpsProperty
                    state={[diskCount, setDiskCount]}
                    name="Хранилище"
                    unit="Гб"
                    step={1}
                    min={1}
                    max={200}
                />
                <VpsProperty
                    state={[dayCount, setDayCount]}
                    name="Дни"
                    step={1}
                    min={1}
                    max={30}
                />
                <VpsProperty
                    state={[vpsCount, setVpsCount]}
                    name="Количество"
                    step={1}
                    min={1}
                    max={100}
                />

                <div className={styles.left}>
                {
                    osList && (
                        <div className={styles.select_os_wrapper}>

                            <p className={styles.label}>Выбор ОС</p>

                            <FormControl sx={{ minWidth: 240 }}
                                size="small"
                                className={styles.select_os}
                            >
                                <Select value={os}
                                    onChange={handleChange}
                                    name="selectOs"
                                    sx={{
                                        marginTop: 0,
                                        "& .MuiSelect-select": {
                                            display: "flex",
                                            alignItems: "center",
                                            marginTop: 0,
                                            border: "1px solid #2A73DE",
                                            borderRadius: "10px!important",
                                            padding: "4px 12px 4px 12px;",
                                            width: "195px",
                                            height: "34px",
                                            gap: "5px",
                                            "& p": {
                                                margin: 0,
                                                fontWeight: 300,
                                                fontSize: "20px",
                                                lineHeight: "22px",
                                                color: "#3D3D3D",
                                            },
                                            "& img": {
                                                marginLeft: "18px",
                                                width: "24px",
                                                height: "24px",
                                            },
                                        },
                                        "& fieldset": {
                                            border: "none",
                                        },
                                        "& .MuiSvgIcon-root": {
                                            display: "none",
                                        }
                                    }}
                                >
                                    {
                                        osList.map(({ id, osName, osImage }) => (
                                            <MenuItem value={id}
                                                key={id}
                                                sx={{
                                                    paddingTop: "10px",
                                                    paddingBottom: "10px",
                                                    paddingLeft: "9px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    "& p": {
                                                        fontFamily: 'Montserrat',
                                                        fontStyle: "normal",
                                                        fontWeight: 300,
                                                        fontSize: "20px",
                                                        lineHeight: "1.2",
                                                        marginTop: 0,
                                                        marginBottom: 0,
                                                        color: "#808080",
                                                    },
                                                    "& img": {
                                                        marginLeft: "18px",
                                                        width: "24px",
                                                        height: "24px",

                                                    },
                                                }}
                                            >
                                                <p>{osName}</p>
                                                <img src={osImage} alt="" />
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    )
                }
                </div>

                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "12px",
                        textAlign: "end",
                    }}
                >
                    <span
                        className="non-selectable"
                        style={{
                            width: props.width ?? "240px",
                            userSelect: "none",
                            fontWeight: "600",
                        }}
                    >
                        Страна сервера
                    </span>
                    <FormControl>
                    <select
                        className="numeric-property"
                        name="selectCountry"
                        style={{ height: "42px", width: "240px" }}
                        onChange={(e) => setCountry(e.target.value)}
                    >
                        <option value={""}>Любая</option>
                        <option value={"Germany"}>Германия</option>
                        <option value={"Finland"}>Финляндия</option>
                        <option value={"Russia"}>Россия</option>
                    </select>
                    </FormControl>
                </div>

                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flexDirection: "row",
                        justifyContent: "center",
                    }}
                >
                    <button
                        className="vps_button non-selectable"
                        onClick={addVps}
                    >
                        Готово
                    </button>
                    <button
                        className="vps_button2 non-selectable"
                        onClick={() => props.onRequestClose()}
                    >
                        Отмена
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default VdsModal;
