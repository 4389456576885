const balancePlusSvg = (
  <svg
    width='14'
    height='14'
    viewBox='0 0 14 14'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M0 7H7M14 7H7M7 7V0M7 7V12.5V13.5'
      stroke='white'
      strokeWidth='1.5'
    />
  </svg>
);

export default balancePlusSvg;
