import React from 'react';

import styles from '../Config.module.scss';
import { Slider } from '@mui/material';

export const CustomSlider = ({
  title,
  def,
  min,
  step,
  max,
  marks,
  buttonTitle,
  count,
  setCount,
  setStopDrag
}) => {
  return (
    <div className={styles.config__item}>
      <h4 className={styles.config__title}>{title}</h4>
      <Slider
        size="small"
        defaultValue={Number(def)}
        aria-label="Small"
        min={Number(min)}
        step={step ? Number(step) : null}
        max={Number(max)}
        value={Number(count)}
        onChange={(e, data) => setCount(data)}
        onChangeCommitted={ (e) => setStopDrag(true) }
        marks={marks}
      />
      <div className={styles.config__info}>
        <span className={styles.config__value}>{count}</span>
        <span className={styles.config__label}>{buttonTitle}</span>
      </div>
    </div>
  );
};
