import React from 'react';
import styles from './Registration.module.scss';
import compose from '../../assets/img/compose.svg';
import ApiManager from '../../ApiManager';
import { useNavigate } from 'react-router-dom';

const Registration = ({ onSuccess }) => {
  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  const navigate = useNavigate();
  const LoginError = {
    WRONG_PASSWORD: 'wrong_password',
    NOT_FILLED: 'not_filled',
  };
  const [responseMessage, setResponseMessage] = React.useState('');
  const [responseMessageSuccess, setResponseMessageSuccess] = React.useState(false);
  const [isEmail, setIsEmail] = React.useState(true);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const [mailSend, setMailSend] = React.useState(false);

  const sendRegisterMail = async () => {
    if (isValidEmail(email)) {
      setIsEmail(true);
    }

    if (email && isEmail) {
      setMailSend(true);
      const response = await ApiManager.registration({ email });
      setMailSend(false);

      const success = response.message.includes("успешно создан");
      setResponseMessage(response.message);
      setResponseMessageSuccess(success);

      if (success) {
        const timer = await setTimeout(() => {
          navigate('/');
        }, 10000);
      }
    }
  };

  return (
    <div className={styles.login}>
      <div className={styles.login__row}>
        <div className={styles.login__rowLeft}>
          <div className={styles.login__left}>
            <h3 className={styles.login__title}>Добро пожаловать!</h3>
            <img src={compose} alt='' className={styles.login__pic} />
          </div>
        </div>
        <div className={styles.login__rowRight}>
          <div className={styles.loginForm}>
            <h3 className={styles.loginForm__title}>Регистрация</h3>
            <h4 className={styles.loginForm__subtitle}>
              На указанный адрес почты будет отправлено письмо с логином и
              паролем, по которым вы сможете войти в личный кабинет
            </h4>
            <div className={styles.loginForm__group}>
              <input
                type='text'
                className={styles.loginForm__control}
                placeholder='Email'
                value={email}
                onChange={(e) => setEmail(e.target.value.trim())}
                id='email'
              />
              <label className={styles.loginForm__label} htmlFor='email'>
                Ваш email
              </label>
            </div>
            <span
              style={
                error == LoginError.NOT_FILLED
                  ? {
                      color: 'red',
                      marginTop: -15,
                      marginBottom: 15,
                      display: 'block',
                    }
                  : { display: 'none' }
              }
            >
              Незаполненное поле
            </span>
            {!isEmail && (
              <span
                style={{
                  color: 'red',
                  marginTop: -15,
                  marginBottom: 15,
                  display: 'block',
                }}
              >
                Некорректный email
              </span>
            )}
            <span
              style={
                error == LoginError.WRONG_PASSWORD
                  ? {
                      color: 'red',
                      marginTop: -15,
                      marginBottom: 15,
                      display: 'block',
                    }
                  : { display: 'none' }
              }
            >
              Логин или пароль введены неверно
            </span>
            <div className={styles.loginForm__submit}>
              <button disabled={mailSend} onClick={() => sendRegisterMail()}>
                ЗАРЕГИСТРИРОВАТЬСЯ
              </button>
            </div>
            {responseMessage && (
              <div className={styles.loginForm__response}>
                {responseMessageSuccess && (
                    <span
                        style={{
                            color: 'green'
                        }}
                    >
                      {responseMessage}
                    </span>
                )}
                {!responseMessageSuccess && (
                    <span
                        style={{
                            color: 'red'
                        }}
                    >
                      {responseMessage}
                    </span>
                )}
              </div>
            )}
            <div className={styles.loginForm__policy}>
              Нажимая на данную кнопку вы соглашаетесь с нашей
              <a
                rel='noreferrer'
                target='_blank'
                href='https://betvds.ru/politika.pdf'
              >
                политикой конфиденциальности
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;