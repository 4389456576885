import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import ApiManager from '../../../ApiManager';
import useConfigurationStore from '../../../stores/configuration';


import styles from '../Config.module.scss';

export default function SelectCountry() {
  const [allCountry, setAllCountry] = useState([]);
  const country = useConfigurationStore((state) => state.country);
  const setCountry = useConfigurationStore((state) => state.setCountry);
  const defaultCountry = {
    name: "All",
    src: null
  };

  const handleChange = (event) => {
      setCountry({ name: event.target.value });
  };

  useEffect(() => {
    (async () => {
      const country = await ApiManager.getListCountry();
      country.unshift(defaultCountry);
      setAllCountry(country);
      setCountry(defaultCountry);
    })()
  }, [setAllCountry, setCountry]);

  const getMenuParams = React.useCallback((country) => {
    return {
        "All": {
            name: "Любая",
        },
        Germany: {
            name: "Германия",
        },
        Finland: {
            name: "Финляндия",
        },
        Russia: {
            name: "Россия"
        }
    }[country];
  }, [])

  const getMenuItem = React.useCallback((key) => {
    const { name } = getMenuParams(key.name);

    return (
        <MenuItem key={key.name} value={key.name} sx={{ minWidth: 190 }}>
            <div className={styles.Select__MenuItem}>
                <div>{name}</div>
                {key.src && <img src={key.src} alt={key.name} />}
            </div>
        </MenuItem>
    );
  }, [getMenuParams])

  return (
    <Box sx={{ minWidth: 190 }}>
      <FormControl sx={{ minWidth: 190 }} size="small" className={styles.Select}>
        <Select
          name = "SelectCountry"
          value={country.name}
          defaultValue={defaultCountry.name}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'Without label' }}
        >
            {getMenuItem(defaultCountry)}
            {allCountry && allCountry.map((country, index)=> index != 0 ? getMenuItem(country) : "")}
        </Select>
      </FormControl>
    </Box>
  );
}
