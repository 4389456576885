import React from 'react';
import { Link } from 'react-router-dom';
import DataManager from '../DataManager';

const Header = () => {
  return (
    <nav>
      <div className="post_repot">
        <div className="logo">
          <Link to="/">
            <img alt="betVDSLogo" src="/img/logo.svg" />
          </Link>
        </div>
        <div className="right_wrap">
          <div className="login">
            <form action="/">
              <input
                type="image"
                alt="logout"
                src="/img/login.svg"
                onClick={() => {
                  DataManager.removeUser();
                }}
              />
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
