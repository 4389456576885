import React from 'react';
import Server from './Server';
import Pagination from './Pagination';

const ServersList = ({
  data,
  allChecked,
  refreshDatas,
  setContModal,
  setContLinuxModal,
  checkVps,
  setCheckVps,
  setAllChecked,
  searchQuery,
  setSSHModal,
  setAutoRenewNeedDepositModal,
  toggleAutoRenewModal,
  refData,
  updateFlag,
  autoRenewIds,
  updateUserInfo,
  updateFlagServer,
  updatedExpirationIds,
  updatedStatusIds,
  updatedPortIds,
  updatedCountryIds,
}) => {
  const [currentPage, setCurrentPage] = React.useState(1);
  const vpsPerPage = 8;

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  const totalVps = data.length;

  const countPage = totalVps / vpsPerPage;

  const lastCountryIndex = currentPage * vpsPerPage;
  const firstCountryIndex = lastCountryIndex - vpsPerPage;
  // const currentVps = data.slice(firstCountryIndex, lastCountryIndex);

  const [currentVps, setCurrentVps] = React.useState(
    data.slice(firstCountryIndex, lastCountryIndex)
  );

  React.useEffect(() => {
    setCurrentVps(data.slice(firstCountryIndex, lastCountryIndex));
  }, [data, currentPage, firstCountryIndex, lastCountryIndex]);

  return (
    <>
      <div className='panel_all'>
        <div className='accordion_fn'>
          <div className='accordion'>
            {currentVps.map((item, index) => (
              <Server
                updateFlag={updateFlag}
                refData={refData}
                data={item}
                key={item.id}
                setContModal={setContModal}
                setContLinuxModal={setContLinuxModal}
                setAllChecked={setAllChecked}
                checkVps={checkVps}
                setCheckVps={setCheckVps}
                refreshDatas={refreshDatas}
                allChecked={allChecked}
                index={index}
                setSSHModal={setSSHModal}
                setAutoRenewNeedDepositModal={setAutoRenewNeedDepositModal}
                toggleAutoRenewModal={toggleAutoRenewModal}
                autoRenewIds={autoRenewIds}
                updateUserInfo={updateUserInfo}
                updateFlagServer={updateFlagServer}
                updatedExpirationIds={updatedExpirationIds}
                updatedStatusIds={updatedStatusIds}
                updatedPortIds={updatedPortIds}
                updatedCountryIds={updatedCountryIds}
              />
            ))}
          </div>
        </div>
      </div>
      {countPage > 1 ? (
        <Pagination
          vpsPerPage={vpsPerPage}
          currentPage={currentPage}
          setCurrentPage={(i) => {
            setCurrentPage(i);
          }}
          totalVps={totalVps}
        />
      ) : null}
    </>
  );
};

export default ServersList;
