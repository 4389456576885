import React from 'react';
import { NavLink } from 'react-router-dom';
import AdminIcon from 'mdi-react/AccountTieIcon';
import StatisticIcon from 'mdi-react/ChartArcIcon';
import DataManager from '../DataManager';
import style from './Sidebar.module.scss';
import moneySvg from '../assets/img/money.svg';
import rubleSvg from '../assets/img/ruble.svg';
import plusSvg from '../assets/img/balancePlusSvg';

const Sidebar = (props) => {
  const [balance, setBalance] = React.useState('');

  React.useEffect(() => {
    setBalance(props.balanceData);
  }, [props.balanceData]);

  return (
    <div className='menu column_pas'>
      <div className='head_menu'>
        {/*<button>*/}
        {/*  <span></span>*/}
        {/*  <span></span>*/}
        {/*  <span></span>*/}
        {/*</button>*/}
      </div>
      <div className='content_menu'>
        <div className='list_items'>
          <div className='item'>
            <NavLink to='/' activeсlassтame='active'>
              <div className='img_item'>
                <svg
                  width='25'
                  height='26'
                  viewBox='0 0 25 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M22.6667 9.10412H2.66667M22.6667 9.10412C23.1087 9.10412 23.5326 8.89066 23.8452 8.51071C24.1577 8.13075 24.3333 7.61542 24.3333 7.07809V3.02603C24.3333 2.48869 24.1577 1.97336 23.8452 1.59341C23.5326 1.21346 23.1087 1 22.6667 1H2.66667C2.22464 1 1.80072 1.21346 1.48816 1.59341C1.17559 1.97336 1 2.48869 1 3.02603V7.07809C1 7.61542 1.17559 8.13075 1.48816 8.51071C1.80072 8.89066 2.22464 9.10412 2.66667 9.10412M22.6667 9.10412C23.1087 9.10412 23.5326 9.31757 23.8452 9.69753C24.1577 10.0775 24.3333 10.5928 24.3333 11.1301V15.1822C24.3333 15.7195 24.1577 16.2349 23.8452 16.6148C23.5326 16.9948 23.1087 17.2082 22.6667 17.2082M2.66667 9.10412C2.22464 9.10412 1.80072 9.31757 1.48816 9.69753C1.17559 10.0775 1 10.5928 1 11.1301V15.1822C1 15.7195 1.17559 16.2349 1.48816 16.6148C1.80072 16.9948 2.22464 17.2082 2.66667 17.2082M22.6667 17.2082H2.66667M22.6667 17.2082C23.1087 17.2082 23.5326 17.4217 23.8452 17.8016C24.1577 18.1816 24.3333 18.6969 24.3333 19.2343V23.2863C24.3333 23.8237 24.1577 24.339 23.8452 24.7189C23.5326 25.0989 23.1087 25.3124 22.6667 25.3124H2.66667C2.22464 25.3124 1.80072 25.0989 1.48816 24.7189C1.17559 24.339 1 23.8237 1 23.2863V19.2343C1 18.6969 1.17559 18.1816 1.48816 17.8016C1.80072 17.4217 2.22464 17.2082 2.66667 17.2082M3.5 5.05206H8.5M3.5 13.1562H8.5M3.5 21.2603H8.5'
                    stroke='white'
                  />
                </svg>
              </div>
              <div className='name_item'>Сервер</div>
            </NavLink>
          </div>
          <div className='item'>
            <NavLink to='/config' activeсlassтame='active'>
              <div className='img_item'>
                <svg
                  width='26'
                  height='27'
                  viewBox='0 0 26 27'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M26 4.46723H22.1929C21.7286 1.89857 19.8714 0 17.6429 0C15.4143 0 13.5571 1.89857 13.0929 4.46723H0V6.70084H13.0929C13.5571 9.26949 15.4143 11.1681 17.6429 11.1681C19.8714 11.1681 21.7286 9.26949 22.1929 6.70084H26V4.46723ZM17.6429 8.93445C16.0643 8.93445 14.8571 7.4826 14.8571 5.58403C14.8571 3.68546 16.0643 2.23361 17.6429 2.23361C19.2214 2.23361 20.4286 3.68546 20.4286 5.58403C20.4286 7.4826 19.2214 8.93445 17.6429 8.93445ZM0 22.3361H3.80714C4.27143 24.9048 6.12857 26.8034 8.35714 26.8034C10.5857 26.8034 12.4429 24.9048 12.9071 22.3361H26V20.1025H12.9071C12.4429 17.5339 10.5857 15.6353 8.35714 15.6353C6.12857 15.6353 4.27143 17.5339 3.80714 20.1025H0V22.3361ZM8.35714 17.8689C9.93571 17.8689 11.1429 19.3207 11.1429 21.2193C11.1429 23.1179 9.93571 24.5697 8.35714 24.5697C6.77857 24.5697 5.57143 23.1179 5.57143 21.2193C5.57143 19.3207 6.77857 17.8689 8.35714 17.8689Z'
                    fill='white'
                  />
                </svg>
              </div>
              <div className='name_item'>Конфигуратор</div>
            </NavLink>
          </div>
          <div className='item'>
            <NavLink
              to='/ref'
              style={{
                borderRadius: '24.5px 0px 0px 24.5px',
                padding: '5px 15px',
              }}
            >
              <div className='img_item'>
                <AdminIcon size={26} color='white' />
                {/*<svg*/}
                {/*  width='26'*/}
                {/*  height='26'*/}
                {/*  viewBox='0 0 26 26'*/}
                {/*  fill='none'*/}
                {/*  xmlns='http://www.w3.org/2000/svg'*/}
                {/*>*/}
                {/*  <path*/}
                {/*    d='M13.7796 11.6996C13.2596 11.5696 12.7396 11.3096 12.3496 10.9196C11.9596 10.7896 11.8296 10.3996 11.8296 10.1396C11.8296 9.87961 11.9596 9.48961 12.2196 9.35961C12.6096 9.09961 12.9996 8.83961 13.3896 8.96961C14.1696 8.96961 14.8196 9.35961 15.2096 9.87961L16.3796 8.31961C15.9896 7.92961 15.5996 7.66961 15.2096 7.40961C14.8196 7.14961 14.2996 7.01961 13.7796 7.01961V5.19961H12.2196V7.01961C11.5696 7.14961 10.9196 7.53961 10.3996 8.05961C9.87961 8.70961 9.48961 9.48961 9.61961 10.2696C9.61961 11.0496 9.87961 11.8296 10.3996 12.3496C11.0496 12.9996 11.9596 13.3896 12.7396 13.7796C13.1296 13.9096 13.6496 14.1696 14.0396 14.4296C14.2996 14.6896 14.4296 15.0796 14.4296 15.4696C14.4296 15.8596 14.2996 16.2496 14.0396 16.6396C13.6496 17.0296 13.1296 17.1596 12.7396 17.1596C12.2196 17.1596 11.5696 17.0296 11.1796 16.6396C10.7896 16.3796 10.3996 15.9896 10.1396 15.5996L8.83961 17.0296C9.22961 17.5496 9.61961 17.9396 10.1396 18.3296C10.7896 18.7196 11.5696 19.1096 12.3496 19.1096V20.7996H13.7796V18.8496C14.5596 18.7196 15.2096 18.3296 15.7296 17.8096C16.3796 17.1596 16.7696 16.1196 16.7696 15.2096C16.7696 14.4296 16.5096 13.5196 15.8596 12.9996C15.2096 12.3496 14.5596 11.9596 13.7796 11.6996ZM12.9996 2.59961C7.27961 2.59961 2.59961 7.27961 2.59961 12.9996C2.59961 18.7196 7.27961 23.3996 12.9996 23.3996C18.7196 23.3996 23.3996 18.7196 23.3996 12.9996C23.3996 7.27961 18.7196 2.59961 12.9996 2.59961ZM12.9996 21.9696C8.05961 21.9696 4.02961 17.9396 4.02961 12.9996C4.02961 8.05961 8.05961 4.02961 12.9996 4.02961C17.9396 4.02961 21.9696 8.05961 21.9696 12.9996C21.9696 17.9396 17.9396 21.9696 12.9996 21.9696Z'*/}
                {/*    fill='white'*/}
                {/*  />*/}
                {/*</svg>*/}
              </div>
              <div className='name_item'>Личный кабинет</div>
            </NavLink>
          </div>
          <div className='item'>
            <a
              href='https://t.me/betvdsru'
              target='_blank'
              rel='noreferrer'
            >
              <div className='img_item'>
                <svg
                  width='26'
                  height='26'
                  viewBox='0 0 26 26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M13.0001 6.5C11.8702 6.50143 10.7871 6.95091 9.98813 7.74985C9.18919 8.54878 8.73971 9.63196 8.73828 10.7618H10.9049C10.9049 9.60592 11.8453 8.66667 13.0001 8.66667C14.1549 8.66667 15.0953 9.60592 15.0953 10.7618C15.0953 11.4097 14.5742 11.8798 13.7779 12.5233C13.5178 12.7271 13.268 12.9437 13.0294 13.1723C11.9482 14.2523 11.9168 15.3996 11.9168 15.5274V16.25H14.0834L14.0824 15.5642C14.0834 15.5469 14.1181 15.1461 14.5601 14.7052C14.7226 14.5427 14.9274 14.3802 15.1397 14.209C15.9836 13.5254 17.2609 12.493 17.2609 10.7618C17.26 9.63198 16.8109 8.54863 16.012 7.7496C15.2132 6.95056 14.13 6.50115 13.0001 6.5ZM11.9168 17.3333H14.0834V19.5H11.9168V17.3333Z'
                    fill='white'
                  />
                  <path
                    d='M12.9993 2.16602C7.02585 2.16602 2.16602 7.02585 2.16602 12.9993C2.16602 18.9728 7.02585 23.8327 12.9993 23.8327C18.9728 23.8327 23.8327 18.9728 23.8327 12.9993C23.8327 7.02585 18.9728 2.16602 12.9993 2.16602ZM12.9993 21.666C8.22077 21.666 4.33268 17.7779 4.33268 12.9993C4.33268 8.22077 8.22077 4.33268 12.9993 4.33268C17.7779 4.33268 21.666 8.22077 21.666 12.9993C21.666 17.7779 17.7779 21.666 12.9993 21.666Z'
                    fill='white'
                  />
                </svg>
              </div>
              <div className='name_item'>Помощь</div>
            </a>
          </div>
          {DataManager.retrieveUser().roleName === 'Owner' ? (
            <div className='item'>
              <NavLink to='/admin'>
                <div className='img_item'>
                  <AdminIcon size={26} color='#FF69B4' />
                </div>
                <div className='name_item'>Админ</div>
              </NavLink>
            </div>
          ) : null}
          {DataManager.retrieveUser().roleName === 'Owner' ? (
            <div className='item'>
              <NavLink to='/nodes/statistic'>
                <div className='img_item'>
                  <StatisticIcon size={26} color='#FFFF' />
                </div>
                <div className='name_item'>Статистика</div>
              </NavLink>
            </div>
          ) : null}
        </div>
      </div>
      <div className='footer_menu'>
        <div
          onClick={() => props.setBalanceModal(true)}
          className={style.balance}
        >
          <div className={style.balance__row}>
            <img src={moneySvg} alt='' />
            Баланс
          </div>
          <div className={style.balance__field}>
            {/* <div className={style.balance__input}></div> */}
            <div className={style.balance__balance}>
              {balance}
              <img src={rubleSvg} alt='' />
            </div>
            <div className={style.balance__btn}>{plusSvg}</div>
          </div>
        </div>
        <div
          className='img_wraps'
          style={{ backgroundImage: 'url(img/bg_down.svg)' }}
        ></div>
      </div>
    </div>
  );
};

export default Sidebar;
