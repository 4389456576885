import React from 'react';
import Button from '@mui/material/Button';
import styles from './Popups.module.scss';
import countBg from '../../assets/img/count-bg.svg';
import close from '../../assets/img/close.svg';
import useConfigurationStore from '../../stores/configuration';
import DataManager from '../../DataManager';
import { useFormik } from 'formik';
import ApiManager from '../../ApiManager';
import { Slider } from '@mui/material';

const Popups = (props) => {
  const [paySys, setPaySys] = React.useState(0);
  const [activeTarif, setActiveTarif] = React.useState(7);
  const [tarifLabel, setTarifLabel] = React.useState(" дней");
  const openSuccessPayModal = props.openSuccessPayModal;

  const processorCount = useConfigurationStore((state) => state.processorCount);
  const memoryCount = useConfigurationStore((state) => state.memoryCount);
  const diskCount = useConfigurationStore((state) => state.diskCount);
  const dayCount = useConfigurationStore((state) => state.dayCount);
  const country = useConfigurationStore((state) =>
    state.country.name === 'All' ? '' : state.country.name
  );
  const os = useConfigurationStore((state) => state.os);
  // const [depositRenewAmount, setDepositRenewAmount] = useState(
  //   10900
  // );

  const buyServer = async () => {
    const countValue = document.getElementById('countValue').value;
    const codeValue = document.getElementById('codeVale').value;

    const serverConfiguration = {
      email: DataManager.retrieveUser().email,
      username: DataManager.retrieveUser().userName,
      cpuCount: processorCount,
      ram: memoryCount,
      disk: diskCount,
      days: dayCount,
      count: countValue,
      code: codeValue,
      country,
      templateId: os,
      paymentMethod: paySys,
      fromBalance: paySys === 2,
    };

    if (countValue > 0) {
      const { success, message } = await ApiManager.createBill(
        serverConfiguration
      );
        if (!success) {
            alert(message);
        } else if (success && paySys === 2) {
            props.setPayModal(false);
            openSuccessPayModal();
        } else {
            window.open(message, '_blank');
        }
    } else alert('Серверов должно быть больше 1');
  };

  const payBalance = async () => {
    const countValue = document.getElementById(
      'balanceDepositCountValue'
    ).value;
    if (countValue > 0) {
      const { success, message } = await ApiManager.depositBalance({
        amount: countValue,
        paymentMethod: paySys,
      });
      if (!success) {
        alert(message);
        return;
      }
      window.open(message, '_blank');
    } else alert('Серверов должно быть больше 1');
  };

  const buyNonCustomServer = async () => {
    const countValue = document.getElementById('countValueNonCust').value;
    const codeValue = document.getElementById('codeValueNonCust').value;
    const serverConfiguration = {
      email: DataManager.retrieveUser().email,
      username: DataManager.retrieveUser().userName,
      cpuCount: 2,
      ram: 8,
      disk: 40,
      days: props.activeTariff,
      count: countValue,
      code: codeValue,
      paymentMethod: paySys,
      fromBalance: paySys === 2,
    };

    if (countValue > 0) {
      const { success, message } = await ApiManager.createBill(
        serverConfiguration
        );

        if (!success) {
            alert(message);
        } else if (success && paySys === 2) {
            props.setPayNonCustomModal(false);
            openSuccessPayModal();
        } else {
            window.open(message, '_blank');
        }
    } else alert('Серверов должно быть больше 1');
  };

  const continueServer = async () => {
    const codeValue = document.getElementById('continueCode').value;
    const servers = localStorage.getItem('serversToContinue');
    const server = localStorage.getItem('serverToContinue');

    const data = servers
      ? {
          days: activeTarif,
          vpsIds: JSON.parse(servers),
          code: codeValue,
          paymentMethod: paySys,
          fromBalance: paySys === 2,
          // fromBalance: false
        }
      : {
          days: activeTarif,
          vpsIds: [server],
          code: codeValue,
          paymentMethod: paySys,
          fromBalance: paySys === 2,
          // fromBalance: false
        };
    localStorage.removeItem('serversToContinue');
    props.setContModal(false);
    props.setContLinuxModal(false);
    const response = await ApiManager.updateSubscription(data);
      
      if (response.success && paySys === 2) {
          openSuccessPayModal();
      } else if (response.success) {
          window.open(response.message, '_blank');
      } else {
          alert(response.message);
      }
  };

  const {
    loadingTwofa,
    activateTwofaData,
    tfa,
    activateTwofa,
    deactivateTwofa,
  } = props;

  const onDeactivateHandler = async () => {
    try {
      await deactivateTwofa();
      props.closeTwofaModal();
    } catch (e) {
      console.log(e);
    }
  };

  const onActivateHandler = async (values) => {
    try {
      const { code } = values;
      formik.resetForm();
      props.closeTwofaModal();
      await activateTwofa(code);
    } catch (e) {
      console.log(e);
      formik.setFieldError('code', e.message);
    } finally {
    }
  };

  React.useEffect(() => {
    if (props.twofaModal) {
      formik.resetForm();
    }
  }, [props.twofaModal]);

  const validate = (values) => {
    const { code } = values;
    const errors = {};
    if (!code) {
      errors.code = 'Введите код';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: onActivateHandler,
    validate,
  });

  const depositBalanceRenew = async () => {
    props.setAutoRenewNeedDepositModal(false);
    props.setNotificationRenewModal(false);
    props.setBalanceModal(true);
    document.getElementById('balanceDepositCountValue').value =
      props.needDepositValue;
  };

  const toggleAutoRenew = async () => {
    if (props.needDepositValue <= props.balanceData) {
      const response = await ApiManager.toggleAutoRenew({
        vpsList: props.autoRenewIds.map((item) => {
          return {
            id: item,
            autoRenewal: true,
            autoRenewalDays: 7,
          };
        }),
      });

      if (response?.success) {
        props.setAutoRenewModal(false);
        props.refreshDatas();
        props.updateUserInfo();
        props.setUpdateFlag(!props.updateFlag);
        // props.setAutoRenewIds([]);
      }
    }
  };

    const setTarifAndLabel = async (value) => {
        setActiveTarif(value);
        if ([1, 21].includes(activeTarif)) {
            setTarifLabel(" день");
        } else if ([2, 3, 4, 22, 23, 24].includes(activeTarif)) {
            setTarifLabel(" дня");
        } else {
            setTarifLabel(" дней")
        }
    }

  return (
    <>
      <div
        className={
          props.refModal
            ? styles.backdrop + ' ' + styles.active
            : styles.backdrop
        }
        onClick={() => props.setRefModal(false)}
      >
        /
      </div>
      <div
        className={
          props.refModal
            ? styles.refPopup + ' ' + styles.active
            : styles.refPopup
        }
      >
        <button
          className={styles.close}
          onClick={() => props.setRefModal(false)}
        >
          <img src={close} alt='closeBtn' />
        </button>
        <h3 className={styles.title}>Как это работает?</h3>
        <p className={styles.txt}>
          При покупке VDS каждому автоматически генерируется персональный
          промокод, дающий 5% с каждой VDS/продления и 5% скидки вашему
          рефералу.
        </p>
        <p className={styles.txt}>
          С помощью не хитрых манипуляций многие договорились с нами о больших
          процентах. Условия рассматриваются индивидуально, главное пишите и мы
          обязательно договоримся.
        </p>
        <h3 className={styles.title}>Как привлекать пользователей?</h3>
        <h4 className={styles.subtitle}>
          Распространенные варианты привлечения клиентов:
        </h4>
        <div className={styles.row}>
          <div className={styles.item}>
            <span
              className={styles.item__count}
              style={{ background: `url(${countBg}) center no-repeat` }}
            >
              1
            </span>
            <p className={styles.item__txt}>
              Посоветуйте сервис друзьям, добавив свою реферальную ссылку
            </p>
          </div>
          <div className={styles.item + ' ' + styles.item_sm}>
            <span
              className={styles.item__count}
              style={{ background: `url(${countBg}) center no-repeat` }}
            >
              2
            </span>
            <p className={styles.item__txt}>
              Опубликуйте пост в своем блоге или странице в соцсети
            </p>
          </div>
          <div className={styles.item}>
            <span
              className={styles.item__count}
              style={{ background: `url(${countBg}) center no-repeat` }}
            >
              3
            </span>
            <p className={styles.item__txt}>
              Посоветуйте сервис в чатах, обусждениях и форумах, добавив свою
              реферальную ссылку
            </p>
          </div>
          <div className={styles.item}>
            <span
              className={styles.item__count}
              style={{ background: `url(${countBg}) center no-repeat` }}
            >
              4
            </span>
            <p className={styles.item__txt}>
              Если у вас есть собственная площадка, вы можете разместить баннер
              на ней
            </p>
          </div>
          <div className={styles.item + ' ' + styles.item_lg}>
            <span
              className={styles.item__count}
              style={{ background: `url(${countBg}) center no-repeat` }}
            >
              5
            </span>
            <p className={styles.item__txt}>
              Напишите обзор, включив в него свою реферальную ссылку и послав во
              все известные интернет издания. Часто хорошие обзоры принимают
              совершенно бесплатно!
            </p>
          </div>
        </div>
      </div>
      <div
        className={props.payModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setPayModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <img src='img/v1.svg' className='fon1' alt='img' />
          <img src='img/v2.svg' className='fon2' alt='img' />
          <div className='close' onClick={() => props.setPayModal(false)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Купить тариф</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Выберите способ оплаты</p>
                  <span>(Выберите для себя наиболее удобный способ) </span>
                </div>
              </div>
              <div className='group_btn1'>
                <div className='flex_btn'>
                  <button
                    className={paySys === 0 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(0)}
                  >
                    <img src='img/payment1.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 1 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(1)}
                  >
                    <img src='img/payment2.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 2 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(2)}
                  >
                    <img src='img/payment3.svg' className='mem1' alt='img' />
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Дополнительно</p>
                  <span>
                    Выберите количество и введите промокод, если он есть
                  </span>
                </div>
              </div>
              <div className='group_btn'>
                <div className='group'>
                  <input
                    type='number'
                    required
                    id='countValue'
                    defaultValue='1'
                    min='1'
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='countValue'>Введите кол-во</label>
                </div>
                <div className='group'>
                  <input type='text' required id='codeVale' />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='codeVale'>Промокод</label>
                </div>
              </div>
              <div className='politic'>
                <a href='politika_konfid.pdf' target='_blank'>
                  Политика безопасности
                </a>
              </div>
              <div className='btn_sub'>
                <button type='submit' onClick={buyServer}>
                  купить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={props.balanceModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setBalanceModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <img src='img/v1.svg' className='fon1' alt='img' />
          <img src='img/v2.svg' className='fon2' alt='img' />
          <div className='close' onClick={() => props.setBalanceModal(false)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Пополнить баланс</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Выберите способ оплаты</p>
                  <span>(Выберите для себя наиболее удобный способ) </span>
                </div>
              </div>
              <div className='group_btn1'>
                <div className='flex_btn'>
                  <button
                    className={paySys === 0 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(0)}
                  >
                    <img src='img/payment1.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 1 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(1)}
                  >
                    <img src='img/payment2.svg' className='mem1' alt='img' />
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Введите сумму пополнения</p>
                  <span>(На данную сумму будет пополнен ваш баланс)</span>
                </div>
              </div>
              <div className='group_btn'>
                <div className='group'>
                  <input
                    type='number'
                    required
                    id='balanceDepositCountValue'
                    defaultValue='1000'
                    min='1'
                    step='0.01'
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='balanceDepositCountValue'>Введите сумму</label>
                </div>
                {/* <div className='group'>
                  <input type='text' required id='codeVale' />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label>Промокод</label>
                </div> */}
              </div>
              {/* <div className='politic'>
                <a href='politika_konfid.pdf' target='_blank'>
                  Политика безопасности
                </a>
              </div> */}
              <div style={{ margin: '50px 0 0' }} className='btn_sub'>
                <button type='submit' onClick={payBalance}>
                  Пополнить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={props.autoRenewNeedDepositModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setAutoRenewNeedDepositModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <div
            className='close'
            onClick={() => props.setAutoRenewNeedDepositModal(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Автопродление</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div style={{ flex: '0 0 auto' }} className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Пополните баланс</p>
                  <span>
                    Для работы автопродления вам необходимо пополнить баланс на
                    сумму
                  </span>
                  <div className={styles.depositAmount}>
                    <span>
                      {props.needDepositValue?.toLocaleString('ru-RU')} рублей
                    </span>
                  </div>
                </div>
              </div>
              <div className='wrap_zag'>
                <div style={{ flex: '0 0 auto' }} className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Включите автопродление</p>
                  <span>
                    После пополнения баланса повторно выберете сервера для
                    автопродления
                  </span>
                </div>
              </div>
              <div style={{ margin: '50px 0 0' }} className='btn_sub'>
                <button
                  style={{ width: '280px' }}
                  type='submit'
                  onClick={depositBalanceRenew}
                >
                  Пополнить баланс
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={props.autoRenewModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setAutoRenewModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='close' onClick={() => props.setAutoRenewModal(false)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Автопродление</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='text' style={{ width: '100%' }}>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      textAlign: 'center',
                    }}
                  >
                    В течение недели с вашего баланса будет списываться сумма
                  </span>
                </div>
              </div>
              <div
                style={{ margin: '50px 0 0' }}
                className={`${styles.wrapZag} wrap_zag`}
              >
                <div className={styles.depositAmount}>
                  <span>
                    {props.needDepositValue?.toLocaleString('ru-RU')} рублей
                  </span>
                </div>
                <div className='btn_sub'>
                  <button
                    style={{ width: '400px' }}
                    type='submit'
                    onClick={toggleAutoRenew}
                  >
                    Включить автопродление
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={props.notificationRenewModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setNotificationRenewModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <div
            className='close'
            onClick={() => props.setNotificationRenewModal(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>{props.notificationAutoRenew.header}</h3>
            <h4
              style={{
                fontSize: '22px',
                fontWeight: 600,
                textAlign: 'center',
                margin: '18px 0 0',
              }}
            >
              {props.notificationAutoRenew.messageHeader}
            </h4>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='text' style={{ width: '100%' }}>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      textAlign: 'center',
                    }}
                  >
                    {props.notificationAutoRenew.message}
                  </span>
                </div>
              </div>
              <div
                style={{ margin: '50px 0 0' }}
                className={`${styles.wrapZag} ${styles.notify} wrap_zag`}
              >
                <div className={styles.depositAmount}>
                  <span>
                    {props.needDepositValue?.toLocaleString('ru-RU')} рублей
                  </span>
                </div>
                <div className='btn_sub'>
                  <button
                    style={{ width: '280px' }}
                    type='submit'
                    onClick={depositBalanceRenew}
                  >
                    Пополнить баланс
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={props.contModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setContModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <img src='img/v1.svg' className='fon1' alt='img' />
          <img src='img/v2.svg' className='fon2' alt='img' />
          <div className='close' onClick={() => props.setContModal(false)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Продлить тариф</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Выберите способ оплаты</p>
                  <span>(Выберите для себя наиболее удобный способ) </span>
                </div>
              </div>
              <div className='group_btn1'>
                <div className='flex_btn'>
                  <button
                    className={paySys === 0 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(0)}
                  >
                    <img src='img/payment1.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 1 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(1)}
                  >
                    <img src='img/payment2.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 2 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(2)}
                  >
                    <img src='img/payment3.svg' className='mem1' alt='img' />
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Кол-во суток</p>
                  <span>Выберите, на сколько суток хотите продлить тариф</span>
                </div>
              </div>
              <div className='group_btn'>
                <div className={styles.tarifSelect}>
                  <button
                    className={
                      activeTarif === 7
                        ? styles.tarifSelect__item +
                          ' ' +
                          styles.tarifSelect__itemActive
                        : styles.tarifSelect__item
                    }
                    onClick={() => setActiveTarif(7)}
                  >
                    7
                  </button>
                  <button
                    className={
                      activeTarif === 14
                        ? styles.tarifSelect__item +
                          ' ' +
                          styles.tarifSelect__itemActive
                        : styles.tarifSelect__item
                    }
                    onClick={() => setActiveTarif(14)}
                  >
                    14
                  </button>
                  <button
                    className={
                      activeTarif === 30
                        ? styles.tarifSelect__item +
                          ' ' +
                          styles.tarifSelect__itemActive
                        : styles.tarifSelect__item
                    }
                    onClick={() => setActiveTarif(30)}
                  >
                    30
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>3</span>
                </div>
                <div className='text'>
                  <p>Дополнительно</p>
                  <span>введите промокод, если он есть</span>
                </div>
              </div>
              <div className='group_btn'>
                <div className='group'>
                  <input type='text' required id='continueCode' />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='continueCode'>Промокод</label>
                </div>
              </div>
              <div className='politic'>
                <a href='politika_konfid.pdf' target='_blank'>
                  Политика безопасности
                </a>
              </div>
              <div className='btn_sub'>
                <button type='submit' onClick={continueServer}>
                  Продлить
                </button>
              </div>
            </div>
          </div>
        </div>
          </div>

      <div
        className={props.contLinuxModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setContLinuxModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <img src='img/v1.svg' className='fon1' alt='img' />
          <img src='img/v2.svg' className='fon2' alt='img' />
          <div className='close' onClick={() => props.setContLinuxModal(false)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Продлить тариф</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Выберите способ оплаты</p>
                  <span>(Выберите для себя наиболее удобный способ) </span>
                </div>
              </div>
              <div className='group_btn1'>
                <div className='flex_btn'>
                  <button
                    className={paySys === 0 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(0)}
                  >
                    <img src='img/payment1.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 1 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(1)}
                  >
                    <img src='img/payment2.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 2 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(2)}
                  >
                    <img src='img/payment3.svg' className='mem1' alt='img' />
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Кол-во суток</p>
                  <span>Выберите, на сколько суток хотите продлить тариф</span>
                </div>
              </div>
              <div className='group_btn'>
                <Slider
                    size="small"
                    defaultValue={1}
                    aria-label="Small"
                    min={1}
                    step={1}
                    max={30}
                    value={activeTarif}
                    onChange={(e, data) => setTarifAndLabel(data)}
                    marks={[{ value: 1, label: "1" }, { value: 30, label: "30" }]}
                />
                <div className={styles.config__info} style={{textAlign: "center"}}>
                    <span className={styles.config__value}>{activeTarif}</span>
                    <span className={styles.config__label}>{tarifLabel}</span>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>3</span>
                </div>
                <div className='text'>
                  <p>Дополнительно</p>
                  <span>введите промокод, если он есть</span>
                </div>
              </div>
              <div className='group_btn'>
                <div className='group'>
                  <input type='text' required id='continueLinuxCode' />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='continueLinuxCode'>Промокод</label>
                </div>
              </div>
              <div className='politic'>
                <a href='politika_konfid.pdf' target='_blank'>
                  Политика безопасности
                </a>
              </div>
              <div className='btn_sub'>
                <button type='submit' onClick={continueServer}>
                  Продлить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={props.payNonCustomModal ? 'shadow opened' : 'shadow'}
        onClick={() => props.setPayNonCustomModal(false)}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <img src='img/v1.svg' className='fon1' alt='img' />
          <img src='img/v2.svg' className='fon2' alt='img' />
          <div
            className='close'
            onClick={() => props.setPayNonCustomModal(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Купить тариф</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>1</span>
                </div>
                <div className='text'>
                  <p>Выберите способ оплаты</p>
                  <span>(Выберите для себя наиболее удобный способ) </span>
                </div>
              </div>
              <div className='group_btn1'>
                <div className='flex_btn'>
                  <button
                    className={paySys === 0 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(0)}
                  >
                    <img src='img/payment1.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 1 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(1)}
                  >
                    <img src='img/payment2.svg' className='mem1' alt='img' />
                  </button>
                  <button
                    className={paySys === 2 ? 'memory active' : 'memory'}
                    onClick={() => setPaySys(2)}
                  >
                    <img src='img/payment3.svg' className='mem1' alt='img' />
                  </button>
                </div>
              </div>
              <div className='wrap_zag'>
                <div className='svg'>
                  <span>2</span>
                </div>
                <div className='text'>
                  <p>Дополнительно</p>
                  <span>
                    Выберите количество и введите промокод, если он есть
                  </span>
                </div>
              </div>
              <div className='group_btn'>
                <div className='group'>
                  <input
                    type='number'
                    required
                    id='countValueNonCust'
                    defaultValue='1'
                  />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='countValueNonCust'>Введите кол-во</label>
                </div>
                <div className='group'>
                  <input type='text' required id='codeValueNonCust' />
                  <span className='highlight'></span>
                  <span className='bar'></span>
                  <label htmlFor='codeValueNonCust'>Промокод</label>
                </div>
              </div>
              <div className='politic'>
                <a href='politika_konfid.pdf' target='_blank'>
                  Политика безопасности
                </a>
              </div>
              <div className='btn_sub'>
                <button type='submit' onClick={buyNonCustomServer}>
                  купить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={props.errorApiText ? 'shadow opened' : 'shadow'}
        onClick={props.closeErrorModal}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='close' onClick={props.closeErrorModal}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>
          <div className='container_modal'>
            <h3>Возникла ошибка</h3>
            <div className='form_ger'>
              <div className='wrap_zag'>
                <div className='text'>
                  <p>{props.errorApiText}</p>
                </div>
              </div>
              <div className='btn_sub'>
                <button type='submit' onClick={props.closeErrorModal}>
                  Ок
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={props.twofaModal ? 'shadow opened' : 'shadow'}
        onClick={props.closeTwofaModal}
      >
        <div
          className='modal_center'
          onClick={(event) => event.stopPropagation()}
        >
          <div className='close' onClick={props.closeTwofaModal}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='26'
              height='26'
              viewBox='0 0 26 26'
              fill='none'
            >
              <path
                d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                stroke='#084C94'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          </div>

          <div className='container_modal'>
            <div className={styles.twofa}>
              <h3>Двухфакторная аутентификация </h3>
              <h4>Настройка Google Authenticator или Authy</h4>
              <ol>
                <li>
                  Установите Google Authenticator (IOS - Android) или Authy (IOS
                  - Android).
                </li>
                <li>
                  В приложении для проверки подлинности выберите значок «+».
                </li>
                <li>
                  Выберите «Сканировать штрих-код (или QR-код)» и используйте
                  камеру телефона для сканирования этого штрих-кода.
                </li>
              </ol>

              {/*if not activated*/}
              {tfa && !tfa.tfAppEnabled && activateTwofaData && (
                <div className={styles.bottom}>
                  <div className={styles.left}>
                    <h4>Сканировать QR-код</h4>
                    <img
                      width='174px'
                      height='174px'
                      src={activateTwofaData.qrCode}
                      alt='qrCode'
                    />
                  </div>
                  <div className={styles.right}>
                    <form onSubmit={formik.handleSubmit}>
                      <h4>Или введите код в свое приложение</h4>
                      <div>Секретный код: {activateTwofaData.key}</div>
                      <h4 styles={{ 'margin-top': '24px' }}>Подтвердить код</h4>
                      <div className={styles.group}>
                        <input
                          className={`${styles.control} ${
                            formik.errors.code && styles.inputError
                          }`}
                          placeholder='Логин'
                          {...formik.getFieldProps('code')}
                          disabled={loadingTwofa}
                        />
                        {formik.errors.code && (
                          <div className={styles.error}>
                            {formik.errors.code}
                          </div>
                        )}
                      </div>
                      <div className={styles.buttons}>
                        <Button
                          type='submit'
                          variant='contained'
                          className={styles.MuiButton}
                          disabled={loadingTwofa}
                        >
                          Активировать
                        </Button>
                      </div>
                    </form>
                  </div>
                </div>
              )}

              {/*if already activated*/}
              {tfa && tfa.tfAppEnabled && (
                <div className={styles.button}>
                  <Button
                    variant='contained'
                    onClick={onDeactivateHandler}
                    className={styles.MuiButton}
                    sx={{
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                    disabled={loadingTwofa}
                  >
                    Отвязать
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        </div>

          <div
              className={props.successPayModal ? 'shadow opened' : 'shadow'}
              onClick={props.closeSuccessPayModal}
          >
              <div
                  className='modal_center'
              >
                  <div className='close' onClick={props.closeSuccessPayModal}>
                      <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='26'
                          height='26'
                          viewBox='0 0 26 26'
                          fill='none'
                      >
                          <path
                              d='M13 25.1905C19.7327 25.1905 25.1905 19.7327 25.1905 13C25.1905 6.26743 19.7327 0.80957 13 0.80957C6.26743 0.80957 0.80957 6.26743 0.80957 13C0.80957 19.7327 6.26743 25.1905 13 25.1905Z'
                              stroke='#084C94'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                          <path
                              d='M17.5716 8.42859L8.42871 17.5714M8.42871 8.42859L17.5716 17.5714L8.42871 8.42859Z'
                              stroke='#084C94'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                          />
                      </svg>
                  </div>

                  <div className='container_modal'>
                      <div className={styles.successPayModal}>
                          <h3>Спасибо за покупку!</h3>
                      </div>
                  </div>
              </div>
          </div>

    </>
  );
};

export default Popups;
