import React from 'react';
import ApiManager from '../../ApiManager';
import styles from './Ref.module.scss';
import Referals from '../../components/Referals';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import IconButton from "@mui/material/IconButton";
import { ClickAwayListener } from '@mui/material';
import clsx from "clsx";

const Ref = (props) => {
    const {
        loadingTwofa, tfa, switchEnableTfa, setUserChatIdValue, setUserPasswordValue
    } = props;

    const chatIdInputRef = React.useRef(null);
    const passwordInputRef = React.useRef(null);
    const chatIdColRef = React.useRef(null);
    const passwordColRef = React.useRef(null);

    const [isEdit, setIsEdit] = React.useState(false);
    const [chatIdValue, setChatIdValue] = React.useState(null);

    const [viewPassword, setViewPassword] = React.useState(false);
    const [isEditPassword, setIsEditPassword] = React.useState(false);
    const [passwordValue, setPasswordValue] = React.useState(null);

    const [checked, setChecked] = React.useState(tfa?.enableTFA);

    React.useEffect(() => {
        if (tfa) {
            setChecked(tfa.enableTFA)
        }
    }, [tfa])

    const onSettingsAuthHandler = props.openTwofaSettings;

    const onSwitchTwofaHandler = async () => {
        if (!loadingTwofa && tfa) {
            const {enableTFA, tfAppEnabled} = tfa;
            if (!tfAppEnabled) {
                props.openTwofaSettings();
            } else {
                switchEnableTfa(!enableTFA)
            }
        }
    }

    const saveChatId = async () => {
        if (isEdit) {
            setIsEdit(false);

            var newChatId = chatIdInputRef.current.value;

            setChatIdValue(newChatId);

            const response = await ApiManager.saveChatId({
                chatId: newChatId
            });

            if (response.succeeded) {
                setUserChatIdValue(newChatId);
            }
        }
    };

    const savePassword = async () => {
        if (isEditPassword) {
            setIsEditPassword(false);
            setViewPassword(false);

            var newPassword = passwordInputRef.current.value;

            setPasswordValue(newPassword);

            const response = await ApiManager.resetPassword({
                password: newPassword
            });

            if (response.success) {
                setUserPasswordValue(newPassword);
            }
        }
    };

    return (
        <div style={{ width: '100%' }}>
            {props.dataRef && props.dataRef.success ? (
                <div className={' i_cont'}>
                    <div className="head_i">
                        <div className="left_potr">
                            <div className="img_wrap">
                                <img src="img/wallet.svg" alt=""/>
                            </div>
                            <div className="title">Реферальная программа</div>
                        </div>
                        <div className="right_potr">
                            <button className="btn_b" onClick={() => props.setRefModal(true)}>
                                подробнее
                            </button>
                        </div>
                    </div>
                    <div className={styles.refInfo}>
                        <div className={`${styles.leftCol} ${styles.user}`}>
                            <h3 className={styles.title}>
                                <img src="img/ref-1.svg" alt=""/>
                                Информация о пользователе
                            </h3>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Логин:</h4>
                                <div className={styles.infoContent}>
                                    <p>{props.userInfo.userName}</p>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Почта:</h4>
                                <div className={styles.infoContent}>
                                    <p>{props.userInfo.email}</p>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Пароль:</h4>
                                <div className={styles.infoContent}>
                                    <div className={styles.infoPasswordWrapper}>
                                        <div
                                            ref={passwordColRef}
                                            className={`divResetPassword eq-password ${isEditPassword && 'eq-is-editing'
                                                }`}
                                        >
                                            {isEditPassword ? (
                                                <input
                                                    id="inputPassword"
                                                    title="Password"
                                                    type={viewPassword ? 'text' : 'password'}
                                                    defaultValue={passwordValue ? passwordValue : props.userInfo.password}
                                                    className={styles.infoPassword}
                                                    name='passwordInput'
                                                    ref={passwordInputRef}
                                                    max={255}
                                                    disabled={!isEditPassword}
                                                />
                                            ) : (
                                                <input 
                                                    className={styles.infoPassword}
                                                    type={viewPassword ? 'text' : 'password'}
                                                    defaultValue={passwordValue ? passwordValue : props.userInfo.password}
                                                    disabled
                                                />
                                            )}

                                            {isEditPassword ? (
                                                <button onClick={savePassword}>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M4.5 12.75l6 6 9-13.5'
                                                        />
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button
                                                    id="editPassowrd"
                                                    title="editPassword"
                                                    onClick={() => {
                                                        setIsEditPassword(true);
                                                        setViewPassword(true);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                                                        />
                                                    </svg>
                                                </button>
                                            )}
                                            <button
                                                id="viewPassword"
                                                title="viewPassword"
                                                className={styles.view}
                                                onClick={() => setViewPassword(!viewPassword)}
                                            >
                                                <img src="img/view.svg" alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Баланс:</h4>
                                <div className={styles.infoContent}>
                                    <p><strong style={{ color: "#3384fa" }}>{props.userInfo.accountBalance + ' '}&#8381;</strong></p>
                                    
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.rightCol} ${styles.ref}`}>
                            <h3 className={styles.title}>
                                <img src="img/ref-2.svg" alt=""/>
                                Реферальная система
                            </h3>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Мой промокод:</h4>
                                <div className={styles.infoContent}>
                                    <p>{props.userInfo.myReferalCode}</p>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Рефералов:</h4>
                                <div className={styles.infoContent}>
                                    <p>
                                        <strong>{props.userInfo.referalsCount}</strong>
                                    </p>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Выплата:</h4>
                                <div className={styles.infoContent}>
                                    <p>
                                        <strong>
                                            {props.userInfo.myReferalUnpaidBalance + ' '}&#8381;
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.leftCol} ${styles.twofa}`}>
                            <h3 className={styles.title}>
                                <img src="img/twofa.svg" alt=""/>
                                Двухфакторная аутентификация
                            </h3>

                            <div className={styles.leftCol_switchWrapper}>

                                <p className={styles.label}>2FA</p>


                                <div className={clsx({
                                    [styles.twofaSwitcher]: true,
                                    [styles.twofaSwitcher_on]: checked,
                                    [styles.twofaSwitcher_disabled]: loadingTwofa,
                                })}
                                     onClick={onSwitchTwofaHandler}
                                >
                                    <div className={styles.dot}/>
                                </div>

                                <IconButton onClick={onSettingsAuthHandler}
                                            sx={{
                                                marginLeft: "20px",
                                                "& .MuiTouchRipple-root": {
                                                    color: "#1976d2",
                                                }
                                            }}
                                            disabled={loadingTwofa}
                                            title="editTFA"
                                >
                                    <SettingsOutlinedIcon sx={{
                                        "& path": {
                                            color: "#1976d2"
                                        }
                                    }}/>
                                </IconButton>

                            </div>
                        </div>

                        <div className={`${styles.rightCol} ${styles.telegram}`}>
                            <h3 className={styles.title}>
                                <img src="img/telegram.svg" alt="" />
                                Уведомления в telegram
                            </h3>
                            <div className={styles.info}>
                                <h4 className={styles.infoTitle}>Мой chat Id:</h4>
                                <div className={styles.infoContent}>
                                    <ClickAwayListener onClickAway={saveChatId}>
                                        <div
                                            ref={chatIdColRef}
                                            className={`divChatId eq-telegram ${isEdit && 'eq-is-editing'
                                                }`}
                                        >
                                            {isEdit ? (
                                                <input
                                                    ref={chatIdInputRef}
                                                    disabled={!isEdit}
                                                    max={255}
                                                    type='text'
                                                    defaultValue={
                                                        chatIdValue ? chatIdValue : props.userInfo.chatId
                                                    }
                                                />
                                            ) : (
                                                <span className='eq-telegram-span'>
                                                        {chatIdValue ? chatIdValue : props.userInfo.chatId}
                                                </span>
                                            )}

                                            {isEdit ? (
                                                <button onClick={saveChatId}>
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M4.5 12.75l6 6 9-13.5'
                                                        />
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button
                                                    id="editChatId"
                                                    title="edit"
                                                    onClick={() => {
                                                        setIsEdit(true);
                                                    }}
                                                >
                                                    <svg
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        fill='none'
                                                        viewBox='0 0 24 24'
                                                        strokeWidth='1.5'
                                                        stroke='currentColor'
                                                        className='w-6 h-6'
                                                    >
                                                        <path
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125'
                                                        />
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                    </ClickAwayListener>
                                </div>
                            </div>
                            <div className={styles.info}>
                                <p className={styles.infoText}>Чтобы узнать ваш chat Id перейдите в нашего <a href="https://t.me/BetVdsBot">telegram-бота</a></p>
                            </div>
                        </div>
                    </div>
                    {<Referals data={props.dataRef.message}/>}
                </div>
            ) : (
                'Загрузка...'
            )}
        </div>
    );
};

export default Ref;
