import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './Home';
import { Configuration } from './Config';
import Ref from './Ref';
import Popups from '../components/Popups/Popups';
import ApiManager from '../ApiManager';
import DataManager from '../DataManager';
import Admin from './Admin';
import useConfigurationStore from '../stores/configuration';
import SshModal from '../components/SshModal/SshModal';
import NodesStatistic from './NodesStatistic/index';

const Lk = () => {
  const allVps = useConfigurationStore((state) => state.allVps);
  const getAllVps = useConfigurationStore((state) => state.getAllVps);
  const errorText = useConfigurationStore((state) => state.errorText);
  const setErrorText = useConfigurationStore((state) => state.setErrorText);
  const location = useLocation();

  const [refModal, setRefModal] = React.useState(false);
  const [activeTariff, setActiveTariff] = React.useState(7);
  const [payModal, setPayModal] = React.useState(false);
  const [autoRenewModal, setAutoRenewModal] = React.useState(false);
  const [autoRenewNeedDepositModal, setAutoRenewNeedDepositModal] = React.useState(false);
  const [notificationRenewModal, setNotificationRenewModal] = React.useState(false);
  const [balanceModal, setBalanceModal] = React.useState(false);
  const [payNonCustomModal, setPayNonCustomModal] = React.useState(false);
  const [chosenServers, setChosenServers] = React.useState([]);
  const [refInf, setRefInf] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [contModal, setContModal] = React.useState(false);
  const [contLinuxModal, setContLinuxModal] = React.useState(false);
  const [refData, setRefData] = React.useState(true);
  const [twofaModal, setTwofaModal] = React.useState(false);
  const [successPayModal, setSuccessPay] = React.useState(false);
  const [needDepositValue, setNeedDepositValue] = React.useState(null);
  const [autoRenewIds, setAutoRenewIds] = React.useState([]);
  const [notificationAutoRenew, setNotificationAutoRenew] = React.useState('');
  const [updateFlag, setUpdateFlag] = React.useState(false);
  const [updateFlagServer, setUpdateFlagServer] = React.useState(false);
  const [balanceData, setBalanceData] = React.useState('...');
  const [isSocketOpenFlag, setSocketOpenFlag] = React.useState(false);
  const [updatedExpirationIds, setUpdatedExpirationIds] = React.useState({});
  const [updatedStatusIds, setUpdatedStatusIds] = React.useState({});
  const [updatedPortIds, setUpdatedPortIds] = React.useState({});
  const [updatedCountryIds, setUpdatedCountryIds] = React.useState({});

  const refreshDatas = () => {
    setRefData(!refData);
  };

  const [userInfo, setUserInfo] = React.useState(DataManager.retrieveUser());
  const [activateTwofaData, setActivateTwofaData] = React.useState(null);
  const [tfa, setTfa] = React.useState(null);
  const [loadingTwofa, setLoadingTwofa] = React.useState(false);

  const [SSHModal, setSSHModal] = React.useState({
    isOpen: false,
    id: 0,
  });

  const updateUserInfo = (noGeneralFlag) => {
    setLoading(false);
    setUpdateFlagServer(!updateFlagServer);
    setUpdateFlag(!updateFlag);
    setUserInfo(DataManager.retrieveUser());
    updateUserInfoData();
  };

  React.useEffect(() => {
    updateUserInfo(true);
  }, [location, refData]);

  const updateUserInfoData = async () => {
  const respData = await ApiManager.getUserInfo();
    setBalanceData(respData.accountBalance);
  };

  const setUserChatIdValue = (chatId) => {
      var userInStorage = DataManager.retrieveUser();
      userInStorage.chatId = chatId;
      userInfo.chatId = chatId;
      DataManager.updateUser(userInStorage);
  };

  const setUserPasswordValue = (password) => {
      var userInStorage = DataManager.retrieveUser();
      userInStorage.password = password;
      userInfo.password = password;
      DataManager.updateUser(userInStorage);
  };

  React.useEffect(() => {
    const socket = ApiManager.notificationWebsocket();
    socket.onopen = function () {
      //console.log('Соединение установлено');
    };
      socket.onmessage = function (event) {
          var wsData = JSON.parse(event.data);

          switch (wsData.type) {
              case 'autoRenewal':
                  setNotificationRenewModal(true);
                  setNotificationAutoRenew(wsData);
                  setNeedDepositValue(wsData.someObject);
                  break;
              case 'vpsExpiration':
                  setUpdatedExpirationIds({
                      id: wsData.message,
                      expirationDate: wsData.someObject,
                  });
                  break;
              case 'userBalance':
                  updateUserInfo(true);
                  break;
              case 'vpsStatus':
                  setUpdatedStatusIds({
                      id: wsData.message,
                      status: wsData.someObject,
                  });
                  break;
              case 'vpsPort':
                  setUpdatedPortIds({
                      id: wsData.message,
                      port: wsData.someObject,
                  });
                  break;
              case 'vpsCountry':
                  setUpdatedCountryIds({
                      id: wsData.message,
                      country: wsData.someObject,
                  });
                  break;
              default:
                  break;
          }

      // классный код фронта
      //if (JSON.parse(event.data).type === 'autoRenewal') {
      //  if (location.pathname === '/') {
      //    setNotificationRenewModal(true);
      //    setNotificationAutoRenew(JSON.parse(event.data));
      //    setNeedDepositValue(JSON.parse(event.data).someObject);
      //  }
      //} else if (JSON.parse(event.data).type === 'vpsExpiration') {
      //  setUpdatedExpirationIds({
      //    id: JSON.parse(event.data).message,
      //    expirationDate: JSON.parse(event.data).someObject,
      //  });
      //  updateUserInfo(true);
      //} else if (JSON.parse(event.data).type === 'userBalance') {
      //  updateUserInfo(true);
      //}
    };
    socket.onerror = function (event) {
      //console.error('Ошибка WebSocket');
      socket.close(); // закрываем подключение к серверу
    };

    socket.onclose = function (event) {
      //console.log('Соединение закрыто');
      setSocketOpenFlag(!isSocketOpenFlag);
    };
  }, [isSocketOpenFlag, updateUserInfo]);

  React.useEffect(() => {
    const onMountHandler = async () => {
      try {
        setLoadingTwofa(true);
        const resp1 = await ApiManager.getStatusTFA();
        if (resp1.success) {
          setTfa(resp1.message);
        }
        // const resp2 = await ApiManager.addTwofaApp();
        // if (resp2.success) {
        //     setActivateTwofaData(resp2.message);
        // }
      } catch (e) {
        console.log(e);
      } finally {
        setLoadingTwofa(false);
      }
    };
    onMountHandler().then();
  }, []);

  // get new activateTwofa data after deactivate
  React.useEffect(() => {
    const handler = async () => {
      try {
        setLoadingTwofa(true);
        if (tfa && !tfa.tfAppEnabled) {
          const resp2 = await ApiManager.addTwofaApp();
          if (resp2.success) {
            setActivateTwofaData(resp2.message);
          }
        }
      } catch (e) {
      } finally {
        setLoadingTwofa(false);
      }
    };
    handler().then();
  }, [tfa]);

  // activate
  const activateTwofa = async (code) => {
    try {
      setLoadingTwofa(true);
      const { message, success } = await ApiManager.verifyApp({ code });
      console.log(message, success);
      if (!success) {
        throw new Error(message);
      }
      const resp1 = await ApiManager.getStatusTFA();
      if (resp1.success) {
        setTfa(resp1.message);
      }
    } catch (e) {
      throw e;
    } finally {
      setLoadingTwofa(false);
    }
  };

  // deactivate
  const deactivateTwofa = async () => {
    try {
      setLoadingTwofa(true);
      await ApiManager.removeTwofaApp();
      await ApiManager.disableTwofa();
      const resp1 = await ApiManager.getStatusTFA();
      if (resp1.success) {
        setTfa(resp1.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTwofa(false);
    }
  };

  // переключить
  const switchEnableTfa = async (enable) => {
    // enable: boolean
    try {
      setLoadingTwofa(true);

      if (enable) {
        await ApiManager.enableTwofa();
      } else {
        await ApiManager.disableTwofa();
      }

      const resp1 = await ApiManager.getStatusTFA();
      if (resp1.success) {
        setTfa(resp1.message);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingTwofa(false);
    }
  };

  const toggleAutoRenewModal = (price, idArr) => {
    if (balanceData < price) {
      setAutoRenewNeedDepositModal(true);
    } else {
      setAutoRenewModal(true);
      setAutoRenewIds(idArr);
    }
    setNeedDepositValue(price);
  };

  React.useEffect(() => {
    (async () => {
      getAllVps();
      let refInf = await ApiManager.myReferals();
      setRefInf(refInf);
    })();
    setLoading(false);
  }, [getAllVps, updateFlag]);

  let updateServersData = [...allVps];
  updateServersData.map(
    (item) =>
      (item.searchString =
        item.config.password +
        item.config.username +
        item.ipAddress +
        item.port)
  );

  return (
    <>
      <Header />
      <div className='main_content'>
        <Sidebar
          balanceData={balanceData}
          userInfo={userInfo}
          setBalanceModal={(state) => setBalanceModal(state)}
        />
        <Routes>
          <Route
            path='/'
            element={
              <Home
                serversData={updateServersData}
                setContModal={(state) => setContModal(state)}
                setContLinuxModal={(state) => setContLinuxModal(state)}
                setAutoRenewNeedDepositModal={(state) =>
                  setAutoRenewNeedDepositModal(state)
                }
                toggleAutoRenewModal={(state, idArr) =>
                  toggleAutoRenewModal(state, idArr)
                }
                setNeedDepositValue={setNeedDepositValue}
                setSSHModal={setSSHModal}
                x={userInfo}
                refreshDatas={refreshDatas}
                loading={loading}
                refData={refData}
                notificationAutoRenew={notificationAutoRenew}
                setNotificationAutoRenew={setNotificationAutoRenew}
                setNotificationRenewModal={setNotificationRenewModal}
                updateFlag={updateFlag}
                autoRenewIds={autoRenewIds}
                updateUserInfo={updateUserInfo}
                updateFlagServer={updateFlagServer}
                updatedExpirationIds={updatedExpirationIds}
                updatedStatusIds={updatedStatusIds}
                updatedPortIds={updatedPortIds}
                updatedCountryIds={updatedCountryIds}
              />
            }
          />
          <Route
            path='/config'
            element={
              <Configuration
                setPayModal={(state) => setPayModal(state)}
                setChosenServers={(state) => setChosenServers(state)}
                setActiveTariff={(state) => setActiveTariff(state)}
                setPayNonCustomModal={(state) => setPayNonCustomModal(state)}
              />
            }
          />
          <Route
            path='ref'
            element={
              <Ref
                userInfo={userInfo}
                dataRef={refInf}
                setRefModal={(state) => setRefModal(state)}
                openTwofaSettings={() => setTwofaModal(true)}
                tfa={tfa}
                loadingTwofa={loadingTwofa}
                switchEnableTfa={switchEnableTfa}
                setUserChatIdValue={setUserChatIdValue}
                setUserPasswordValue={setUserPasswordValue}
              />
            }
          />
          <Route
            path='admin'
            element={
              DataManager.retrieveUser().roleName === 'Owner' ? (
                <Admin />
              ) : (
                <Home
                  serversData={updateServersData}
                  setContModal={(state) => setContModal(state)}
                  userInfo={userInfo}
                  refreshDatas={refreshDatas}
                  loading={loading}
                />
              )
            }
          />
          <Route
            path='nodes/statistic'
            element={
              DataManager.retrieveUser().roleName === 'Owner' ? (
                <NodesStatistic />
              ) : (
                <Home
                  serversData={updateServersData}
                  setContModal={(state) => setContModal(state)}
                  userInfo={userInfo}
                  refreshDatas={refreshDatas}
                  loading={loading}
                />
              )
            }
          />
        </Routes>
      </div>

      {SSHModal.isOpen && (
        <SshModal SSHModal={SSHModal} setSSHModal={setSSHModal} />
      )}

      <Popups
        balanceData={balanceData}
        updateUserInfo={updateUserInfo}
        updateFlag={updateFlag}
        setUpdateFlag={setUpdateFlag}
        notificationAutoRenew={notificationAutoRenew}
        refreshDatas={refreshDatas}
        autoRenewIds={autoRenewIds}
        userInfo={userInfo}
        refModal={refModal}
        setRefModal={(state) => setRefModal(state)}
        payModal={payModal}
        setPayModal={(state) => setPayModal(state)}
        autoRenewModal={autoRenewModal}
        setAutoRenewModal={(state) => setAutoRenewModal(state)}
        autoRenewNeedDepositModal={autoRenewNeedDepositModal}
        setAutoRenewNeedDepositModal={(state) =>
          setAutoRenewNeedDepositModal(state)
        }
        notificationRenewModal={notificationRenewModal}
        setNotificationRenewModal={setNotificationRenewModal}
        needDepositValue={needDepositValue}
        balanceModal={balanceModal}
        setBalanceModal={(state) => setBalanceModal(state)}
        activeTariff={activeTariff}
        setActiveTariff={(state) => setActiveTariff(state)}
        payNonCustomModal={payNonCustomModal}
        setPayNonCustomModal={(state) => setPayNonCustomModal(state)}
        chosenServers={chosenServers}
        contModal={contModal}
        setContModal={(state) => setContModal(state)}
        contLinuxModal={contLinuxModal}
        setContLinuxModal={(state) => setContLinuxModal(state)}
        errorApiText={errorText}
        closeErrorModal={() => setErrorText('')}
        twofaModal={twofaModal}
        closeTwofaModal={() => setTwofaModal(false)}
        successPayModal={successPayModal}
        openSuccessPayModal={() => setSuccessPay(true)}
        closeSuccessPayModal={() => setSuccessPay(false)}
        loadingTwofa={loadingTwofa}
        activateTwofaData={activateTwofaData}
        tfa={tfa}
        activateTwofa={activateTwofa}
        deactivateTwofa={deactivateTwofa}
        setAutoRenewIds={setAutoRenewIds}
      />
    </>
  );
};

export default Lk;