import { useState, useEffect } from 'react';
import Statistic from '../../components/Nodes/Statistic/Statistic';
import ApiManager from "../../ApiManager";

const NodesStatistic = () => {
    const [nodesNamesList, setNodesNamesList] = useState([]);

    useEffect(() => {
        const onMount = async () => {
            try {
                const { message, success } = await ApiManager.getNodesNamesList();

                if (success) {
                    setNodesNamesList(message);
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        onMount().then();
    }, [setNodesNamesList]);

    return (
        <div className="i_cont column_pas">
            <Statistic nodesNamesList={nodesNamesList} />
        </div>
    );
};

export default NodesStatistic;