import React from 'react';
import ApiManager from '../../ApiManager';
import AdminIcon from 'mdi-react/AccountTieIcon';
import ArrowIcon from 'mdi-react/ArrowBottomRightThickIcon'
import MagnifyIcon from 'mdi-react/MagnifyIcon'
import VdsInfo from './Vps/VdsInfo';
import VdsModal from './Vps/VdsModal';
import UserModal from './User/UserModal';
import PencilIcon from 'mdi-react/PencilIcon'
import ConfirmationModal from './ConfirmationModal';
import DataManager from '../../DataManager';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import ArrowRightIcon from 'mdi-react/ArrowRightIcon'
import VpsTimeModal from './Vps/VpsTimeModal';

const Admin = (props) => {
  const [users, setUsers] = React.useState([]);
  const [query, setQuery] = React.useState("");
  const [tableHeight, setTableHeight] = React.useState(0)
  const [pageViewModel, setPageViewModel] = React.useState({ current: 1, total: 0 });
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [vdsModalParams, setVdsModalParams] = React.useState({ visible: false, user: null });
  const [userModalParams, setUserModalParams] = React.useState({ visible: false, user: null });
  const [confirmationModalParams, setConfirmationModalParams] = React.useState({ title: "", message: "", visible: false, callback: null });
  const [expandedUsers, setExpandedUsers] = React.useState([])
  const [selectedUserIds, setSelectedUserIds] = React.useState([])
  const [showingTimeModal, setShowingTimeModal] = React.useState(false)
  const [selectedVds, setSelectedVds] = React.useState([])

  const showUserVds = (user) => {
    if (expandedUsers.includes(user)){
      setExpandedUsers(expandedUsers.filter((val) => val.id !== user.id))
    }
    else{
      setExpandedUsers([...expandedUsers, user])
    }
  }
  
  const addUserIdToSelected = (userId) => {
    var pageNums = []
    if (selectedUserIds.includes(userId)){
      setSelectedUserIds(selectedUserIds.filter((id) => id !== userId))
    }
    else{
      setSelectedUserIds([...selectedUserIds, userId])
    }
  }

  const deleteUsers = async () => {
    if (selectedUserIds.length === 0) return
      let message = users.filter((user) => selectedUserIds.includes(user.id)).map((user) => user.userName).join(", ")
      setConfirmationModalParams({ visible: true, title: "Удалить пользователей", message: "Вы уверены, что хотите удалить выбранных пользователей?\n" + message, callback: async () => { 
      let response = await ApiManager.deleteUsers({ usersIds: selectedUserIds })
      setSelectedUserIds([])
      setUsers([])
    }})

    }

  const execRef = () => {
    if (selectedUserIds.length === 0) return
    let message = users.filter((user) => selectedUserIds.includes(user.id)).map((user) => user.userName).join(", ")
    setConfirmationModalParams({ visible: true, title: "Обнулить реф", message: "Вы уверены, что хотите обнулить реф для выбранных пользователей?\n" + message, callback: async () => { 
      let response = await ApiManager.resetUnpaidBalance({ usersIds: selectedUserIds })
      setUsers([])
      setSelectedUserIds([])
    }})
    }

  React.useEffect(() => {
    (async () => {
      if (users.length === 0){
        let usersArray = await ApiManager.listUsers(query, pageViewModel.current)
        setUsers(usersArray.message.usersList)
        setPageViewModel({ current: pageViewModel.current, total: usersArray.message.pageViewModel.totalPages })
        setFilteredUsers(usersArray.message.usersList)
        setExpandedUsers(users.filter((user) => expandedUsers.map((u) => u.id).includes(user.id)))
      }

      let column_pas = document.getElementsByClassName('column_pas')[0];
      let head_i = document.getElementsByClassName('head_i')[0];
      let search_panel = document.getElementsByClassName('search_panel')[0];
      let big_custom_polt = document.getElementsByClassName('big_custom_polt')[0];

      let cp = column_pas ? column_pas.offsetHeight : 0;
      let hi = head_i ? head_i.offsetHeight : 0;
      let sp = search_panel ? search_panel.offsetHeight - 100 : 0;
      let bcp = big_custom_polt ? big_custom_polt.offsetHeight - 100 : 0;

      setTableHeight(cp - hi - sp - bcp)
    })();
  })

  let isOwner = DataManager.retrieveUser().roleName === "Owner"
  var pageNums = [...Array(pageViewModel.total).keys()].map((v) => v + 1)

  if (pageViewModel.total >= 15) {
    pageNums = []
    if (pageViewModel.current - 4 <= 1){
      for (let i = 1; i <= 5; i++){
        pageNums.push(i)
      }
      pageNums.push(-1)
      pageNums.push(pageViewModel.total)
    } else if (pageViewModel.total - pageViewModel.current <= 4){
      pageNums.push(1)
      pageNums.push(-1)
      for (let i = pageViewModel.total - 4; i <= pageViewModel.total; i++){
        pageNums.push(i)
      }       
    } else {
      pageNums.push(1)
      pageNums.push(-1)
      pageNums.push(pageViewModel.current - 1)
      pageNums.push(pageViewModel.current)
      pageNums.push(pageViewModel.current + 1)
      pageNums.push(-1)
      pageNums.push(pageViewModel.total)        
    }
  }

    const Hidden = () => {
        return <div />;
    };

    let pagination = pageViewModel.total === 1 ? (<Hidden />) : (
        <div className='pagination non-selectable'>
            <div className='arrow' onClick={() => setPageViewModel({ total: pageViewModel.total, current: pageViewModel.current - 1 < 1 ? 1 : pageViewModel.current - 1 }) & setUsers([])}><ArrowLeftIcon color='#aaaaaa' size={24} /></div>
            {
                pageNums.map((page) =>
                    <div key={page} onClick={() => {
                        if (page !== -1) {
                            setPageViewModel({ total: pageViewModel.total, current: page })
                            setUsers([])
                            setSelectedUserIds([])
                            setSelectedVds([])
                        }
                    }} className={'page' + (pageViewModel.current === page ? ' selected' : '')}>{page === -1 ? "..." : page}</div>
                )
            }
            <div className='arrow' onClick={() => setPageViewModel({ total: pageViewModel.total, current: pageViewModel.current + 1 > pageViewModel.total ? pageViewModel.total : pageViewModel.current + 1 }) & setUsers([])}><ArrowRightIcon color='#aaaaaa' size={24} /></div>
        </div>);
    return (
    <div className="i_cont column_pas">
      <VpsTimeModal onRequestClose={() => setShowingTimeModal(false)} vpsUpdated={() => { setUsers([]); setSelectedUserIds([]); setSelectedVds([]); }} params={ {visible: showingTimeModal, vds: selectedVds} } />
      <ConfirmationModal params={confirmationModalParams} onRequestClose={() => setConfirmationModalParams({ title: "", message: "", visible: false, callback: null })} /> 
      <VdsModal user={vdsModalParams.user} isOpen={vdsModalParams.visible} onRequestClose={() => setVdsModalParams({ visible: false, user: null })} vpsUpdated={
        () => {
          setUsers([]);
          setSelectedUserIds([]);
          setSelectedVds([]);
        }
      }/>
      <UserModal userCreated={() => setUsers([])} params={userModalParams} onRequestClose={() => setUserModalParams({ visible: false, user: null})}/>
      <div className="head_i">
        <div className="left_potr">
          <div className="img_wrap">
            <AdminIcon size={50} color="#2A73DE"/>
          </div>
          <div className="title">Пользователи</div>
        </div>
        <div className="right_potr">
        <button
            className='btn_b2'
            onClick={() => setUserModalParams({ visible: true, user: null })}
          >
            Добавить
          </button>
          <button
            className='btn_b2'
            onClick={deleteUsers}
          >
            Удалить
          </button>
          <button
            className='btn_b2'
            onClick={execRef}
          >
            Реф $
          </button>
          <button
            className='btn_b2'
            onClick={() => setShowingTimeModal(selectedVds.length !== 0)}
          >
            Добавить подписку
          </button>
        </div>
      </div>
      <div className="search_panel">
        <div className="lable">
          <i className="search_go">
            <MagnifyIcon style={{ marginTop: '4px'}} size={20} color="#939393"/>
          </i>
          <input
            type="text"
            placeholder="Поиск"
            autoComplete="off"
            name="search"
            onChange={(e) => setQuery(e.target.value) & setUsers([])}
          />
        </div>
      </div>

      <div className="overflo_wrap" >
        <div className="over_hiden">
          <div className="inner_hiden">
            <div className="big_custom_polt">
              <div className="coups clearfix" style={{height: 'auto'}}>
                <label className="containerCH">
                  <input
                    type="checkbox"
                    className="selAll"
                    name="selectAll"
                    checked={selectedUserIds.length === users.length && selectedUserIds.length !== 0}
                    disabled
                    placeholder="Выбрать всех пользователей"
                  />
                  <span className="checkmark" onClick={() => selectedUserIds.length === users.length ? setSelectedUserIds([]) : setSelectedUserIds(users.map((u) => u.id))}></span>
                </label>
                <div className="users_fields" style={{ marginLeft: '20px' }}>
                  <div style={{width: 'calc(' + (isOwner ? '30' : '37.5') + '%)'}}>Логин</div>
                  <div style={{width: 'calc(' + (isOwner ? '30' : '37.5') + '%)'}} className='big-column'>E-mail</div>
                  <div style={{width: 'calc(8%)'}} className='big-column'>Кол-во VPS</div>
                  {isOwner ? <div style={{width: 'calc(8%)'}}>Реф. оплата</div> : <hidden/>}
                  {isOwner ? <div style={{width: 'calc(9%)'}}>Баланс</div> : <hidden/>}
                  {isOwner ? <div style={{width: 'calc(15%)'}}>Дата регистрации</div> : <hidden/>}
                </div>
              </div>
            </div>
            <div className="users_table" style={{ height: tableHeight + 'px'}}>
            {
              filteredUsers.map((user, num) =>
              <div key={user.id}>
                <div className={"users_values " + (num % 2 === 0 ? "even" : "odd")}>
                <label className="containerCH" style={{ marginLeft: '20px'}}>
                  <input
                    type="checkbox"
                    className="selAll"
                    name="selectUser"
                    checked={selectedUserIds.includes(user.id)}
                    disabled
                    placeholder="Выбрать пользователя"
                  />
                  <span className="checkmark" onClick={() => addUserIdToSelected(user.id)}></span>
                </label>
                  <div style={{width: 'calc(' + (isOwner ? '30' : '37.5') + '%)'}}>{user.userName} <ArrowIcon style={{ cursor: 'pointer' }} onClick={() => showUserVds(user)} size={20} color="#2A73DE"/> <PencilIcon  style={{ cursor: 'pointer' }} onClick={() => setUserModalParams({user: user, visible: true})} size={20} color="#2A73DE"/></div>
                  <div style={{width: 'calc(' + (isOwner ? '30' : '37.5') +'%)'}}>{user.email}</div>
                  <div style={{width: 'calc(8%)'}}>{user.vps.length}</div>
                  {isOwner ? <div  style={{width: 'calc(8%)'}}>{user.myReferalUnpaidBalance}</div> : <hidden/>}     
                  {isOwner ? <div  style={{width: 'calc(9%)'}}>{user.accountBalance}</div> : <hidden/>} 
                  {isOwner ? <div  style={{width: 'calc(15%)'}}>{new Date(user.register * 1000).toLocaleString()}</div> : <hidden/>}           
                </div>
                <VdsInfo
                vdsSelectedAll={(vps) => {
                  let added = vps.filter((v) => !selectedVds.includes(v))
                  let removed = selectedVds.filter((v) => vps.includes(v))
                  setSelectedVds([...selectedVds, ...added].filter((v) => !removed.includes(v)))
                }}
                vdsSelected={(vps) => selectedVds.includes(vps) ? setSelectedVds(selectedVds.filter((v) => v !== vps)) : setSelectedVds([...selectedVds, vps]) } vpsUpdated={() => setUsers([]) & setShowingTimeModal(false)} onRequestAdd={() => setVdsModalParams({ visible: true, user: user })} userUpdated={(user) => {
                  setUsers(users.map((newUser) => newUser.id === user.id ? user : newUser))
                  setFilteredUsers(filteredUsers.map((newUser) => newUser.id === user.id ? user : newUser))
                  setExpandedUsers(users.filter((user) => expandedUsers.map((u) => u.id).includes(user.id)))
                }} user={expandedUsers.map((u) => u.id).includes(user.id) ? user : null}/>
              </div>
              )
            }
            </div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '16px' }}>
              {pagination}
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  };
  
  export default Admin;