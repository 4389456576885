class DataManager {
  static saveUserInSessionStorage(user) {
    sessionStorage.setItem('userInfo', JSON.stringify(user));
    localStorage.setItem('userDataSaved', 'false');
  }

  static saveUserInLocalStorage(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
    localStorage.setItem('userDataSaved', 'true');
  }

  static retrieveUser() {
    if (localStorage.getItem('userDataSaved') === 'false') {
      let user = JSON.parse(sessionStorage.getItem('userInfo'));
      if (user != null) {
        if (new Date().getTime() / 1000 - user.expiration > 0) return null;
      }
      return user;
    }

    let user = JSON.parse(localStorage.getItem('userInfo'));
    if (user != null) {
      if (new Date().getTime() / 1000 - user.expiration > 0) return null;
    }
    return user;
  }

  static updateUser(user) {
    if (localStorage.getItem('userDataSaved') === 'false') {
        sessionStorage.setItem('userInfo', JSON.stringify(user));

        return user;
    }

    localStorage.setItem('userInfo', JSON.stringify(user));
    return user;
  }

  static removeUser() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default DataManager;
