import { useState, useEffect } from 'react';
import GaugeStatistic from './GaugeStatistic';
import FiltersStatistic from './FiltersStatistic';
import { Box, Typography } from '@mui/material';
import ApiManager from '../../../ApiManager';

const Statistic = (props) => {
    const {
        nodesNamesList
    } = props;

    const [checked, setChecked] = useState([]);
    const [nodesNames, setNodesNames] = useState([]);
    const [nodesList, setNodesList] = useState([]);

    useEffect(() => {
        const onMount = async () => {
            try {
                const { message, success } = await ApiManager.statisticList({ nodesIds: nodesNames });

                if (success) {
                    setNodesList(message);
                }
            } catch (e) {
                console.log(e.message)
            }
        }
        onMount().then();
    }, [nodesNames, setNodesList]);

    return (
        <Box>
            <Typography>Статистика</Typography>
            <Box display="flex">
                <FiltersStatistic nodesNamesList={nodesNamesList} checked={checked} setChecked={setChecked} nodesNames={nodesNames} setNodesNames={setNodesNames} />
                <GaugeStatistic nodesList={nodesList} />
            </Box>
        </Box>
    );
}

export default Statistic