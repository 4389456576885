import React from 'react';
import Modal from 'react-modal';

const rootContainer = document.getElementById("root");
Modal.setAppElement(rootContainer);

const ConfirmationModal = (props) => {
  const modalStyle = {
    content: {
      width: '800px',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      borderRadius: '10px',
      transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <Modal
      isOpen={props.params.visible}
      onRequestClose={() => props.onRequestClose()}
      style={modalStyle}
      setAppElement={rootContainer}
      contentLabel="Confirmation Modal"
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
        <span className="non-selectable" style={{ fontWeight: '600' }}>
          {props.params.title}
        </span>
        <pre className="non-selectable">{props.params.message}</pre>
        <br />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <button
            className="vps_button non-selectable"
            onClick={async () =>
              props.params.callback != null
                ? (await props.params.callback()) & props.onRequestClose()
                : ''
            }
          >
            Да
          </button>
          <button
            className="vps_button2 non-selectable"
            onClick={() => props.onRequestClose()}
          >
            Нет
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;