import React from 'react';
import ConfirmationModal from '../ConfirmationModal';
import ApiManager from '../../../ApiManager';
import useConfigurationStore from '../../../stores/configuration';

const handleError = (response) => {
    if (!response.success) {
        return response.message;
    }
    return null;
};

const VdsInfo = (props) => {
  const [selectedVds, setSelectedVds] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);
  const [confirmationModalParams, setConfirmationModalParams] = React.useState(
    []
    );

  const setErrorText = useConfigurationStore((state) => state.setErrorText);

  const checkVps = (vps) => {
    if (selectedVds.includes(vps)) {
      setSelectedVds(selectedVds.filter((v) => v.id !== vps.id));
    } else {
      setSelectedVds((prev) => [...prev, vps]);
    }
      props.vdsSelected(vps);
  };

  const deleteVds = async () => {
    if (selectedVds.length === 0) return;
    let message = selectedVds
      .map((vds) => vds.ipAddress + ':' + vds.port)
      .join(', ');
    setConfirmationModalParams({
      visible: true,
      title: 'Удалить Vds',
      message: 'Вы уверены, что хотите удалить эти VDS?\n' + message,
      callback: async () => {
        await ApiManager.deleteVps({
          vpsIds: selectedVds.map((vds) => vds.id),
        });
        let updatedUser = props.user;
        updatedUser.vps = updatedUser.vps.filter(
          (vps) => !selectedVds.map((vds) => vds.id).includes(vps.id)
        );
        props.userUpdated(updatedUser);
      },
    });
  };

  const rebootVps = async (serverId) => {
    const dataFetch = {
      id: serverId,
    };
    const errorText = handleError(await ApiManager.rebootAdminVps(dataFetch));

    if (errorText) {
      setErrorText(errorText);
    } else {
        setDisabled(true);

        // **** 60 sec disabled button ****
        setTimeout(() => setDisabled(false), 60000);
    }
  };

  return props.user == null ? (
    <span />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        visible: props.visible ? 'visible' : 'collapse',
      }}
    >
      <ConfirmationModal
        params={confirmationModalParams}
        onRequestClose={() =>
          setConfirmationModalParams({
            title: '',
            message: '',
            visible: false,
            callback: null,
          })
        }
      />
      <div className="vps_table">
        <div className="vps_values header">
          <div>
            <label className="containerCH">
              <input
                type="checkbox"
                className="selAll"
                name="selectAllVds"
                checked={selectedVds.length === props.user.vps.length}
                disabled
                placeholder="Выбрать все Вдс"
              />
              <span
                className="checkmark"
                onClick={() => {
                  let wasSelected = selectedVds;
                  selectedVds.length === props.user.vps.length
                    ? setSelectedVds([])
                    : setSelectedVds(props.user.vps);
                    props.vdsSelectedAll(
                      props.user.vps.length === wasSelected.length
                      ? props.user.vps
                      : props.user.vps.filter((v) => !wasSelected.includes(v))
                  );
                }}
              ></span>
            </label>
            <span style={{ width: '30px', fontWeight: '600' }}>№</span>
          </div>
          <div>Регион</div>
          <div>Конфиг</div>
          <div>IP-адрес</div>
          <div>Порт</div>
          <div>Истекает</div>
          <div>Перезапустить</div>
        </div>
        {props.user.vps.map((vps, num) => (
          <div
            key={vps.id}
            className={'vps_values ' + (num % 2 === 0 ? 'even' : 'odd')}
          >
            <div>
              <label className="containerCH">
                <input
                  type="checkbox"
                  className="selAll"
                  name="selectVds"
                  checked={selectedVds.includes(vps)}
                  disabled
                  placeholder="Выбрать Вдс"
                />
                <span
                  className="checkmark"
                  onClick={() => checkVps(vps)}
                ></span>
              </label>
              <span style={{ width: '30px' }}>{num + 1} </span>
            </div>
            <div className='flagCh eq-flag'>
                {vps.config.country ? (
                    <img
                        src={`img/${vps.config.country.toLowerCase()}.svg`}
                        alt=''
                    />
                ) : (
                    <img src={`img/unknown.svg`} alt='' />
                )}
            </div>
            <div>
              {`${vps.config.cpu} cpu ${vps.config.ram} ram ${vps.config.disk} SSD`}
            </div>
            <div className={'vps-selected-' + selectedVds.includes(vps)}>
              {vps.ipAddress}
            </div>
            <div>{vps.port}</div>
            <div>{new Date(vps.expirationDate * 1000).toLocaleString()}</div>
            <div>              
              <button
                title='Перезапустить VDS'
                className='reload'
                disabled={disabled}
                style={{
                  opacity: '1',
                  cursor: 'pointer',
                }}
                onClick={() => rebootVps(vps.id)}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'
                >
                  <rect width='20' height='20.1724' rx='2' fill='#EBBF25' />
                  <path
                    d='M15 11.2781C15 9.83739 14.4732 8.45568 13.5355 7.43695C12.5979 6.41821 11.3261 5.84589 10 5.84589V4.03516L7.77778 6.44947L10 8.86378V7.05305C12.15 7.05305 13.8889 8.94225 13.8889 11.2781C13.8889 13.6139 12.15 15.5031 10 15.5031C9.28567 15.504 8.58496 15.2909 7.97483 14.8872C7.36471 14.4836 6.86879 13.9051 6.54155 13.2153C6.2143 12.5254 6.06841 11.7509 6.11988 10.9769C6.17136 10.2028 6.41822 9.45915 6.83333 8.82757L6.03333 7.97049C5.54961 8.65533 5.22308 9.45557 5.08081 10.3049C4.93853 11.1542 4.98461 12.0281 5.21523 12.8542C5.44584 13.6803 5.85434 14.4348 6.40689 15.0551C6.95943 15.6755 7.6401 16.1438 8.39248 16.4213C9.14487 16.6989 9.9473 16.7776 10.7333 16.651C11.5193 16.5244 12.2661 16.1961 12.912 15.6934C13.5579 15.1906 14.0843 14.5278 14.4474 13.76C14.8105 12.9921 14.9999 12.1413 15 11.2781Z'
                    fill='white'
                  />
                </svg>
              </button>
            </div>
          </div>
        ))}
      </div>
      <br />
      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <button onClick={props.onRequestAdd} className="vps_button">
          Добавить VDS
        </button>
        <button className="vps_button" onClick={deleteVds}>
          Удалить VDS
        </button>
      </div>
      <br />
    </div>
  );
};

export default VdsInfo;
